import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
// import {useSharedState} from "../../../../../"
import {
  DatePicker,
  Row,
  Modal,
  Col,
  Card,
  Typography,
  Progress,
  List,
  Tooltip,
  Button,
  Layout,
  Avatar,
  Space,
  Image,
  Dropdown,
  theme,
  Divider,
  Select,
  Input,
  Tabs
} from "antd";
import {  InfoCircleOutlined, PlusCircleOutlined, EditOutlined, PlusOutlined, CaretDownOutlined, DeleteOutlined} from "@ant-design/icons";
import ReactEcharts from "echarts-for-react";
import { Content } from "antd/es/layout/layout";
import "./TLLScoreCard.scss";
import {
  UserOutlined
} from "@ant-design/icons";
import moreImage from "../../../../../Assets/images/3 dots.svg";
import TextArea from "antd/es/input/TextArea";
import PodiumDetails from "../../../../../Assets/json/podiumDetails.json"
// import ScoreCards from "../../../../../Assets/json/tllScoreCard.json"
import { Table, Badge } from "antd";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { useNavigate } from "react-router-dom";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { useParams } from "react-router-dom";
import { useSharedState } from "../../../../../Shared/Services/WindowSizeService";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
const { Title, Text } = Typography;




const TLLScoreCard = ({ ...props }) => {
  const windowSize$ = useSharedState();
  const Data = [
    {
      "key": 4,
      "id": 4,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    },
    {
      "key": 5,
      "id": 5,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    },
    {
      "key": 6,
      "id": 6,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    }
  ];

  const items = [
    {
      label: <Space>Edit</Space>,
      key: '0',
      evtype: 'edit'
    }
  ];

  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };


  const [cardContents, setCardContents] = useState({});
  const [marketing, setMarketingContent] = useState({});
  const [medic, setMedic] = useState({});
  const [competitive, setCompetitive] = useState({});
  const [edit, setEdit] = useState({})
  const [dropdownVisible, setDropdownVisible] = useState({
    tllCard: false,
    marketingCard: false,
    medicalAndSalesCard: false,
    competitiveInvolvementCard: false,
  });

  const [nobleStatements, setNobleStatements] = useState({})
  //  const [podiumData, setPodiumData] = useState(PodiumDetails);
  const [podiumData, setPodiumData] = useState([]);
  const [scorecard, setScoreCard] = useState({});
  const [guageChart, setGuageChart] = useState({});
  const [barChart, setBarChart] = useState({});
  const [year, setYear] = useState([]);
  const [defaultYear, setDefaultYear] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditStatementModal, setOpenEditStatementModal] = useState(false);
  const [editStatement, setEditStatement] = useState({
    data: []
  });
  const [openAdvScoreModal, setOpenAdvScoreModal] = useState(false);
  const [tableData, setTableData] = useState({ headerContent: [], bodyContent: [] });
  const [input, setInput] = useState();
  const [columnData, setColumnData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [currentTab, setCurrentTab] = useState('viewScorecard');
  const [editScore, setEditScore] = useState({
    headerContent: [],
    bodyContent: []
  });
  const [tableScorecardData, setTableScorecardData] = useState({ headerContent: [], bodyContent: [] });
  const [count, setCount] = useState(2);
  const [scorecardSource, setScorecardSource] = useState();
  const [defaultScoreColumns, setdefaultScoreColumns] = useState();
  const [backupTableData, setBackupTableData] = useState();
  const [conferences, setConferences] = useState([]);
  const [meeting, setMeeting] = useState([]);
  const [submittedList, setSubmittedList] = useState([]);
  const [newOption, setNewOption] = useState({});
  const [podiumRecord,setPodiumRecord] = useState({
    record: {},
    index: null
  });
  const [podiumEditStatement, setPodiumEditStatement] = useState({
    data: []
  });
  const [surveyId, setSurveyId] = useState(null);
  const inputRef = useRef(null);



  //  const windowSize$ = useSharedState();

  const tableDataRef = useRef();
  tableDataRef.current = tableData;

  const tableScoreDataRef = useRef();
  tableScoreDataRef.current = tableScorecardData;

  const handleMoreClick = () => {
    const updatedBodyContent = [...podiumData.bodyContent, ...Data];
    const updatedData = {
      headerContent: podiumData?.headerContent,
      bodyContent: updatedBodyContent
    }
    setPodiumData(updatedData);
  }


  // Echarts Bar Chart Configuration
  const getHorizontalBarChartOption = () => {
    return {
      title: {
        text: "",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "value",
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          fontSize: 12,
        }
      },
      yAxis: {
        type: "category",
        data: ["Q1 2024", "Q2 2024", "Q3 2024", "Q4 2024"],
        axisLabel: {
          interval: 0,
          fontSize: 12,
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      series: [
        {
          name: "Score",
          type: "bar",
          data: [4.0, 3.8, 5.0, 4.5],
          itemStyle: {
            color: "#215694", // Set the bar color here
          },
          barWidth: "50%",
          label: {
            show: true,
            position: "right", // Place the label on the right side for horizontal bars
            fontSize: 12,
          },
        },
      ],
    };
  };


  const getGaugeChartOption = () => {
    return {
      title: {
        text: "Latest Score",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      series: [
        {
          name: "Gauge",
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 5,
          radius: "80%",
          splitNumber: 5,
          progress: {
            show: true,
            roundCap: true,
            width: 25,
            itemStyle: {
              color: "#59CD0D", // Set the progress color here
            },
          },
          pointer: {
            show: false, // Hide the pointer
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 25,
              color: [[1, "#D9D9D9"]],
            },
          },
          axisTick: {
            show: false, // Hide axis ticks
          },
          splitLine: {
            show: false, // Hide split lines
          },
          axisLabel: {
            show: false, // Hide axis labels
          },
          title: {
            show: false, // Hide the title inside the gauge
          },
          detail: {
            show: true,
            valueAnimation: true,
            fontSize: 16,
            color: "#777",
            formatter: "{value}/5",
            offsetCenter: [0, "40%"],
          },
          data: [
            {
              value: 4.5, // Latest score value
            },
          ],
        },
      ],
    };
  };

  const renderViewScorecard = () => {
    return (
      <div className="list-container">
        <div className="list-container">
          <p className="headerLabel">TLL Advocacy Score & Engagement Plan</p>
          <Card
            styles={{ body: { padding: '20px' } }}
            className="Tll-scorecard-Card"
            style={{
              padding: 0,
              maxheight: "80vh",
              backgroundColor: "#F7F7F7",
              borderRadius: "15px",
            }}
          >
            <div>
              <Row gutter={[16, 16]}>
                {/* Profile Section */}
                <Col xs={24} md={8}>
                  <Card>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Avatar
                              style={{
                                verticalAlign: "middle",
                                margin: '8px 7rem'
                              }}
                              size={120}
                              src={imgSrc}
                            >
                              <UserOutlined style={{ fontSize: "100px" }} />
                            </Avatar>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ marginTop: "20px" }}>
                        <Card bordered={false}>
                          <Row gutter={8}>
                            <Col span={10}>
                              <Title level={5}>Select The Plan</Title>
                            </Col>
                            <Col span={12}>
                              <Select
                                name="engagementYears"
                                allowClear
                                style={{
                                  width: '90%',
                                }}
                                value={defaultYear}
                                showSearch
                                placeholder="Please select"
                                onChange={(value, label) => { onYearChange(value, label, "kols") }}
                                options={year}
                                defaultValue={defaultYear}
                              />
                            </Col>
                          </Row>
                          <ReactEcharts
                            option={guageChart}
                            style={{ height: "220px", marginTop: "20px" }}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {/* Advocacy Score and Bar Chart */}
                <Col xs={24} md={16}>
                  <Card>
                    <Row>
                      <Col xs={2} md={2}>
                        <Tooltip title="Click to see the Advocacy Score Description">
                          <Button
                            icon={<InfoCircleOutlined />}
                            shape="circle"
                          />
                        </Tooltip>
                      </Col>
                      <Col xs={2} md={2}>
                        <Tooltip title="Click here to route to survey">
                          <Button
                            icon={<PlusCircleOutlined />}
                            shape="circle"
                            onClick={() => routeToSurvey()}
                          />
                        </Tooltip>
                        {/* <Button className="edit-meet-button" type="primary" onClick={() => routeToSurvey()}>Add</Button> */}
                      </Col>
                      {/* <Col xs={2} md={2}>
                        <Tooltip title="Click here to edit advocacy score">
                          <Button
                            icon={<EditOutlined />}
                            shape="circle"
                            onClick={() => getAdvocacyScorecardList()}
                          />
                        </Tooltip>
                      </Col> */}
                    </Row>
                    <ReactEcharts
                      // getHorizontalBarChartOption()
                      option={barChart}
                      style={{ height: "380px", marginTop: "20px" }}
                    />
                  </Card>
                  <Modal
                    title="Edit Advocacy Score"
                    centered
                    open={openAdvScoreModal}
                    onCancel={() => setOpenAdvScoreModal(false)}
                    width={800}
                    style={{ maxHeight: '80vh' }}
                    footer={[
                    ]}
                  >
                    <Table
                      columns={columnData}
                      dataSource={bodyData}
                      pagination={false}
                      scroll={{
                        y: "calc(100vh - 65vh)",
                      }}
                    // scroll={{ y: 300 }}  
                    // style={{ maxHeight: '400px' }}
                    />
                  </Modal>
                </Col>

              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Card
                    title={"TLL"}
                    extra={
                      <div style={{ display: 'flex' }}>
                        <Dropdown
                          visible={dropdownVisible.tllCard}
                          onVisibleChange={(flag) => setDropdownVisible({ ...dropdownVisible, tllCard: true })}
                          placement="bottom"
                          dropdownRender={(menu) => (
                            <div style={contentStyle}>
                              <Space className='pointer'
                                onClick={() => {
                                  setEdit({ ...edit, tllCard: true });
                                  setDropdownVisible({ ...dropdownVisible, tllCard: false });
                                }}
                                style={{
                                  padding: 8,
                                }}
                              >
                                Edit
                              </Space>
                            </div>
                          )}
                          trigger={['click']}
                        >
                          <p onClick={(e) => { e.preventDefault() }}>
                            <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                              <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                            </Space>
                          </p>
                        </Dropdown>
                      </div>
                    }
                    styles={{ header: { backgroundColor: "#F3E8E8" } }}
                  >
                    {edit?.tllCard ? (
                      <div>
                        <TextArea
                          value={cardContents?.tllCardContent.join('\n')}
                          onChange={(e) => setCardContents({ ...cardContents, tllCardContent: e.target.value.split('\n') })}
                          rows={6}
                          style={{ width: '100%' }}
                        />
                        <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                          <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, tllCard: false }) }}>Cancel</Button>
                          <Button type="primary" onClick={() => {
                            handleEditAndSave("tll", cardContents?.tllCardContent);
                            setEdit({ ...edit, tllCard: false });
                          }}>Save</Button>
                        </Row>
                      </div>
                    ) :
                      <List
                        dataSource={cardContents?.tllCardContent}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span> {/* Bullet point */}
                            {item}
                          </List.Item>
                        )}
                      />}
                  </Card>
                </Col>

                <Col xs={24}>
                  <Card
                    title={"Marketing"}
                    styles={{ header: { backgroundColor: "#F3E8E8" } }}
                    extra={
                      <div style={{ display: 'flex' }}>
                        <Dropdown
                          visible={dropdownVisible.marketingCard}
                          onVisibleChange={(flag) => setDropdownVisible({ ...dropdownVisible, marketingCard: true })}
                          placement="bottom"
                          dropdownRender={(menu) => (
                            <div style={contentStyle}>
                              <Space className='pointer'
                                onClick={() => {
                                  setEdit({ ...edit, marketingCard: true });
                                  setDropdownVisible({ ...dropdownVisible, marketingCard: false });
                                }}
                                style={{
                                  padding: 8,
                                }}
                              >
                                Edit
                              </Space>
                            </div>
                          )}
                          trigger={['click']}
                        >
                          <p onClick={(e) => { e.preventDefault() }}>
                            <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                              <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                            </Space>
                          </p>
                        </Dropdown>
                      </div>
                    }
                  >
                    {edit?.marketingCard ? (
                      <div>
                        <TextArea
                          value={scorecard?.marketing.join('\n')}
                          onChange={(e) => setScoreCard({ ...scorecard, marketing: e.target.value.split('\n') })}
                          rows={6}
                          style={{ width: '100%' }}
                        />
                        <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                          <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, marketingCard: false }) }}>Cancel</Button>
                          <Button type="primary" onClick={() => {
                            handleEditAndSave("marketing", scorecard?.marketing);
                            setEdit({ ...edit, marketingCard: false });
                          }}>Save</Button>
                        </Row>
                      </div>
                    ) :
                      <List
                        dataSource={scorecard?.marketing}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                            {item}
                          </List.Item>
                        )}
                      />}
                    {/* <List
                    dataSource={scorecard?.marketing}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  /> */}
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    title={"Medical and Sales"}
                    styles={{ header: { backgroundColor: "#F3E8E8" } }}
                    extra={
                      <div style={{ display: 'flex' }}>
                        <Dropdown
                          visible={dropdownVisible.medicalAndSalesCard}
                          onVisibleChange={(flag) => setDropdownVisible({ ...dropdownVisible, medicalAndSalesCard: true })}
                          placement="bottom"
                          dropdownRender={(menu) => (
                            <div style={contentStyle}>
                              <Space className='pointer'
                                onClick={() => {
                                  setEdit({ ...edit, medicalAndSalesCard: true });
                                  setDropdownVisible({ ...dropdownVisible, medicalAndSalesCard: false });
                                }}
                                style={{
                                  padding: 8,
                                }}
                              >
                                Edit
                              </Space>
                            </div>
                          )}
                          trigger={['click']}
                        >
                          <p onClick={(e) => { e.preventDefault() }}>
                            <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                              <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                            </Space>
                          </p>
                        </Dropdown>
                      </div>
                    }
                  >
                    {edit?.medicalAndSalesCard ? (
                      <div>
                        <TextArea
                          value={scorecard?.medical_and_sales.join('\n')}
                          onChange={(e) => setScoreCard({ ...scorecard, medical_and_sales: e.target.value.split('\n') })}
                          rows={6}
                          style={{ width: '100%' }}
                        />
                        <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                          <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, marketingCard: false }) }}>Cancel</Button>
                          <Button type="primary" onClick={() => {
                            handleEditAndSave("medical_and_sales", scorecard?.medical_and_sales);
                            setEdit({ ...edit, medicalAndSalesCard: false });
                          }}>Save</Button>
                        </Row>
                      </div>
                    ) :
                      <List
                        dataSource={scorecard?.medical_and_sales}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                            {item}
                          </List.Item>
                        )}
                      />}
                    {/* <List
                  dataSource={scorecard?.medical_and_sales}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  /> */}
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    title={"Competitive Involvement"}
                    styles={{ header: { backgroundColor: "#F3E8E8" } }}
                    extra={
                      <div style={{ display: 'flex' }}>
                        <Dropdown
                          visible={dropdownVisible.competitiveInvolvementCard}
                          onVisibleChange={(flag) => setDropdownVisible({ ...dropdownVisible, competitiveInvolvementCard: true })}
                          placement="bottom"
                          dropdownRender={(menu) => (
                            <div style={contentStyle}>
                              <Space className='pointer'
                                onClick={() => {
                                  setEdit({ ...edit, competitiveInvolvementCard: true });
                                  setDropdownVisible({ ...dropdownVisible, competitiveInvolvementCard: false });
                                }}
                                style={{
                                  padding: 8,
                                }}
                              >
                                Edit
                              </Space>
                            </div>
                          )}
                          trigger={['click']}
                        >
                          <p onClick={(e) => { e.preventDefault() }}>
                            <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                              <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                            </Space>
                          </p>
                        </Dropdown>
                      </div>
                    }
                  >
                    {edit?.competitiveInvolvementCard ? (
                      <div className="adv-container">
                        <TextArea
                          value={scorecard?.competitive_involvement.join('\n')}
                          onChange={(e) => setScoreCard({ ...scorecard, competitive_involvement: e.target.value.split('\n') })}
                          rows={6}
                          style={{ width: '100%' }}
                        />
                        <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                          <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, competitiveInvolvementCard: false }) }}>Cancel</Button>
                          <Button type="primary" onClick={() => {
                            handleEditAndSave("competitive_involvement", scorecard?.competitive_involvement);
                            setEdit({ ...edit, competitiveInvolvementCard: false });
                          }}>Save</Button>
                        </Row>
                      </div>
                    ) :
                      <List
                        dataSource={scorecard?.competitive_involvement}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                            {item}
                          </List.Item>
                        )}
                      />}

                    {/* <List
                  dataSource={scorecard?.competitive_involvement}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  /> */}
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    title={"Podium Noble Statements"}
                    styles={{ header: { backgroundColor: "#F3E8E8" } }}
                    extra={<Button type="primary">More</Button>} >
                    {/* onClick={handleMoreClick} */}
                    <Table
                      columns={podiumData?.headerContent}
                      dataSource={podiumData?.bodyContent}
                      rowKey={(record) => record.id}
                    />
                    <Modal
                    title="Edit Statement"
                    centered
                    open={openEditModal}
                    onCancel={() => setOpenEditModal(false)}
                    footer={[
                      <Button key="submit" onClick={() => { setOpenEditModal(false) }}>
                        Cancel
                      </Button>,
                      <Button onClick={() => { onEditSave() }}>Save</Button>,
                    ]}
                  >
                    <TextArea
                      value={editStatement?.data.join('\n')}
                      onChange={(e) => setEditStatement({ ...editStatement, data: e.target.value.split('\n') })}
                      rows={6}
                      style={{ width: '100%' }}
                    />
                  </Modal>
                  </Card>
                </Col>

              </Row>

              {/* <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            <Col xs={24} md={12}>
                <Card
                title={"Podium Noble Statements"}
                styles={{header:{backgroundColor:"#F3E8E8"}}} >
                  <List
                    dataSource={[
                      'Maui Derm 2/2024 - "There has been nothing new for SD for years... until now".',
                      "SDDS - Highlighted rapid itch relief, durable response and reiterated that it is a disease predominately of inflammation.",
                    ]}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              </Col>
              <Col xs={24} md={12}>
                <Card
                title={"Competitive Involvement"}
                styles={{header:{backgroundColor:"#F3E8E8"}}} >
                  <List
                    dataSource={[
                      "Advisor - Multiple Companies.",
                      'Author - "Tapinarof cream 1% once daily for the treatment of plaque psoriasis: Patient-reported outcomes from the PSOARING 3 trial".',
                    ]}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              </Col>
            </Row> */}
            </div>
          </Card>
        </div>
      </div>
    )
  }

  const getScorecardList = (record, conf) => {
    if (record) {
      record['conference'] = conf;
    }
    let rowData = record;
    let payload = {
      profileId: Number(id),
      advYear: null,
      rowData: rowData ? rowData : {}
    }
    ServiceUtils.postRequest('tllScorePodium', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          if(openEditStatementModal) {
            setOpenEditStatementModal(false);
          }
          setTableScorecardData({ ...response.data.data });
          setBackupTableData(JSON.parse(JSON.stringify(response.data.data)));
          setConferences(response?.data?.data?.conference);
          setSubmittedList(response?.data?.data?.submitted_by);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const handleScorecardAdd = () => {
    const newData = {
      key: count + 1,
      submitted_by: "",
      quarter: dayjs(new Date()),
      conference: "",
      meeting: "",
      advocacy_score: "",
      followup_notes: "",
      id: "",
      podium_statement: [],
      addNew: true,
      survey_response_id: null,
      change: false
    };
    setScorecardSource([...scorecardSource, newData]);
    let tempBodyData = [...tableScorecardData?.bodyContent];
    tempBodyData.unshift(newData);
    setTableScorecardData({
      ...tableScorecardData,
      bodyContent: tempBodyData
    })
    setCount(count + 1);
  };


  const onAction = (data) => {
    console.log(data, "on Add")
  }


  const deleteRecord = (record) => {}

  const changeTableColumnStrcuture = () => {
    console.log(tableScorecardData,"tableScorecardData")
    let tempHeaderData = [...tableScorecardData?.headerContent];
    let tempBodyData = [...tableScorecardData?.bodyContent];
    let reqColumns = JSON.parse(
      JSON.stringify(tempHeaderData)
    );
    const handleNewOptionAddition = (key, value) => {
      console.log(key, value, "key, value")
      setNewOption(value)
      // setNewOption({
      //   ...newOption,
      //   [key]: value,
      // });
    };
    reqColumns?.map((el) => {
      if (el.key === "podium_statement") {
        el.render = (_, record, index) => (
          <div style={{ display: 'flex' }}>
            <>
              <TextArea
                rows={3}
                defaultValue={record?.podium_statement ? record?.podium_statement : undefined}
                value={record?.podium_statement ? record?.podium_statement : undefined}
                style={{ width: "100%" }}
                maxLength={500}
                placeholder="Podium Statements"
                autoComplete='off'
                disabled
                // disabled={record?.disabled}
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={
                  (e) =>
                    handleDropdownChange(
                      e.target.value,
                      "podium_statement",
                      "",
                      record,
                      index
                    )
                }
              />
              <div  style={{marginLeft:"5px",zIndex:"100"}} onClick={() => {
                openModalEdit(record,index)
              }}><EditOutlined /></div>
            </>
          </div>
        );
      } else if (el.key === "advocacy_score") {
        el.render = (_, record, index) => {
          return (
            <Input
              value={record?.advocacy_score}
              name="Advocacy Score"
              style={{ width: "90%" }}
              onChange={(e) => { handleDropdownChange(e.target.value, "advocacy_score", "", record, index) }}
              placeholder="Enter Advocacy Score"
            />
          );
        };
      } else if (el.key === "action") {
        el.render = (_, record, index) => (
          <div className={classNames("", {
            "width-action-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
          })} style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <Button onClick={() => {
                submitData(record,'submit')
              }} className="edit-meet-button" type="primary" disabled={check(tableScorecardData?.bodyContent[index])}>
                Submit
              </Button>
            </div>
            <div onClick={() => {submitData(record,'delete')}}><DeleteOutlined /></div>
          </div>
        );
      } else if (el.key === "meeting") {
        el.render = (_, record, index) => (
          <div className="action-on">
            <Select
              placeholder="Select Action on"
              defaultValue={record?.meeting ? record?.meeting : undefined}
              value={record?.meeting ? record?.meeting : undefined}
              style={{ width: "100%" }}
              // disabled={record?.disabled}
              maxTagCount="responsive"
              onChange={
                (value, label) =>
                  handleDropdownChange(value, "meeting", label, record, index)
              }
            >
              {
                record?.meetings &&
                record?.meetings?.length > 0 &&
                record?.meetings.map((el) => (
                  <Option value={el.value}>{el.label}</Option>
                ))
              }
            </Select>
          </div>
        );
      }
      else if (el.key === "conference") {
        el.render = (_, record, index) => (
          <div className="action-on">
            <Select
              placeholder="Select Conference"
              defaultValue={record?.conference ? record?.conference : undefined}
              value={record?.conference ? record?.conference : undefined}
              style={{ width: "100%" }}
              maxTagCount="responsive"
              onChange={
                (value, label) =>
                  handleDropdownChange(value, "conference", label, record, index)
              }
            >
              {conferences &&
                conferences.length > 0 &&
                conferences.map((el) => (
                  <Option value={el.value}>{el.label}</Option>
                ))}
            </Select>
            {/* <Select
              placeholder="Select Conference"
              style={{ width: "100%" }}
              suffixIcon={
                <CaretDownOutlined
                  style={{ pointerEvents: "none" }}
                />
              }
              onChange={
                (value, label) =>
                  handleDropdownChange(value, "conference", label, record, index)
              }
              getPopupContainer={(trigger) => document.body}
              showSearch
              filterOption={(input, option) =>
                option.label
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      padding: "0 8px 4px",
                      gap: "8px",
                    }}
                  >
                    <Input
                      value={newOption[el?.key]}
                      onChange={(e) =>
                        handleNewOptionAddition(
                          el?.key,
                          e?.target?.value
                        )
                      }
                      onKeyDown={(e) => e.stopPropagation()}
                      style={{ flex: 1 }}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        console.log(newOption, "newOption")
                        onAction({
                          type: "addNewOption",
                          value: newOption[el?.key],
                          payload: {
                            key: el?.key,
                            data: {
                              [el?.key]:
                                newOption[el?.key],
                            },
                          },
                        });
                        inputRef.current.blur();
                        if (inputRef.current) {
                          inputRef.current.blur();
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </>
              )}
              options={conferences}
              defaultValue={record?.conference ? record?.conference : undefined}
              value={record?.conference ? record?.conference : undefined}
            /> */}
          </div>
        );
      } else if (el.key === "submitted_by") {
        el.render = (_, record, index) => (
          <div className="action-on">
            <Select
              placeholder="Select Submitted By"
              defaultValue={record?.submitted_by ? record?.submitted_by : undefined}
              value={record?.submitted_by ? record?.submitted_by : undefined}
              style={{ width: "100%" }}
              // disabled={record?.disabled}
              maxTagCount="responsive"
              onChange={
                (value, label) =>
                  handleDropdownChange(value, "submitted_by", label, record, index)
              }
            >
              {submittedList &&
                submittedList.length > 0 &&
                submittedList.map((el) => (
                  <Option value={el.value}>{el.label}</Option>
                ))}
            </Select>
          </div>
        );
      } else if (el.key === "quarter") {
        el.render = (_, record, index) => (
          <div className="action-on">
            <DatePicker
              style={{
                width: "100%",
                ...(record?.borderless && {
                  padding: "4px 2px",
                  color: "black",
                  fontWeight: 600,
                }),
              }}
              placeholder={record?.placeholder}
              // disabled={record?.disabled}
              onChange={(event) => handleDropdownChange(event?.$d, "quarter", "", record, index)}
              allowClear={false}
              autoComplete="off"
              picker="quarter"
              bordered={!record?.borderless}
              value={dayjs(new Date(record?.quarter))}
            />
          </div>
        );
      }
    });
    setdefaultScoreColumns(reqColumns);
    setScorecardSource(tempBodyData);
  };

  const check = (rec) => {
    // console.log(!rec?.change);
    return !rec?.change
  }

  const submitData = (data,type) => {
    let tempData = JSON.parse(JSON.stringify(data));
    let payload = {
      profileId: Number(id),
      data: { ...tempData },
      type: type,
      created_time: new Date(),
      created_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    ServiceUtils.postRequest('tllAddEdit', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          getScorecardList();
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const fetchMeetingList = (record, value) => {
    let payload = {
      "id": {
        "kolId": Number(id),
        "conferenceId": value
      }
    }
    ServiceUtils.postRequest('surveyMeetings', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setMeeting(response.data.data);
          setMeetingsToRecord(response.data.data,record?.survey_response_id);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const setMeetingsToRecord = (data,id) => {
    const bodyContent = [...tableScorecardData?.bodyContent];
    bodyContent.forEach(record => {
      if(record?.survey_response_id === id) {
        record['meetings'] = data;
        return;
      }
    })
    console.log(bodyContent,"bodyContent")
    setTableScorecardData({
      ...tableScorecardData,
      bodyContent: bodyContent
    })
  }

  const handleDropdownChange = (value, key, label, record, index) => {
    console.log(record, "selected record")
    if (key === 'conference') {
      setSurveyId(record?.survey_response_id);
      fetchMeetingList(record,value);
      // getScorecardList(record, value);
    }
    let tempTableData = [...tableScoreDataRef?.current?.bodyContent];
    tempTableData[index][key] = value;
    if (checkSubmitStatus(record, index)) {
      tempTableData[index]['change'] = true;
    }
    else {
      tempTableData[index]['change'] = false;
    }
    setTableScorecardData({
      ...tableScorecardData,
      bodyContent: tempTableData
    })
    // setCurrentIndex(index);
  };

  const checkSubmitStatus = (data, index) => {
    let reqFlag = true;
    if (backupTableData && (index >= 0)) {
      if (data) {
        if (data?.edit) {
          if (data?.action_status && data?.action_assigned_to && data?.action_on) {
            reqFlag = true;
          }
          else {
            reqFlag = false;
          }
        }
        else if (data?.created === false || data?.created === true) {
          let backUpData = JSON.parse(JSON.stringify(backupTableData?.bodyContent[index]));
          if (Number(backUpData?.action_assigned_to) !== Number(data?.action_assigned_to) || Number(backUpData?.action_on) !== Number(data?.action_on) || Number(backUpData?.action_status) !== Number(data?.action_status)) {
            reqFlag = true;
          }
          else {
            reqFlag = false;
          }
        }
      }
    }
    console.log(reqFlag)
    return reqFlag;
  }

  const columns = defaultScoreColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row) => {
    const newData = [...scorecardSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setScorecardSource(newData);
  };

  const renderEditScorecard = () => {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                className="edit-meet-button"
                disabled={props?.followUpRequired}
                onClick={handleScorecardAdd}
                type="primary"
                style={{
                  marginBottom: 8,
                  marginTop: 8,
                }}
              >
                <PlusOutlined fill="white" style={{ fontSize: '15px' }} />Create a Scorecard
              </Button>
              {/* </div> */}
            </Col>
          </Row>
        </div>
        <div
          className={classNames("", {
            "edit-container":
              windowSize$ === "lg" ||
              windowSize$ === "xl" ||
              windowSize$ === "xxl",
            "edit-container-small":
              windowSize$ === "xs" ||
              windowSize$ === "sm" ||
              windowSize$ === "md",
          })}>
          <Table
            columns={columns}
            dataSource={scorecardSource}
            bordered
            pagination={false}
            scroll={{
              y: "calc(100vh - 270px)",
            }}
          />
        </div>
      </div>
    )
  }



  const tabOptions = [
    {
      key: "viewScorecard",
      label: "View Scorecard ",
      children: renderViewScorecard(),
    },
    {
      key: "editScorecard",
      label: "Edit Scorecard",
      // children: getScorecardList(),
      children: renderEditScorecard(),
    },
  ];

  const onChangeTab = (key) => {
    console.log(key, "key")
    setCurrentTab(key);
    if (key === 'editScorecard') {
      // getScorecardList();
      renderEditScorecard();
    } else {
      renderViewScorecard();
    }
  };

  const onYearChange = (value, label, name) => {
    setDefaultYear(value)
  }


  useEffect(() => {
    fetchProfileData();
    fetchpodiumTableData();
    fetchCards();
    getScorecardList();
  }, [])

  useEffect(() => {
    fetchScoreCardData();
  }, [defaultYear]);

  useEffect(() => {
    changeColumnStrcuture();
  }, [tableData])

  useEffect(() => {
    // renderEditScorecard();
    changeTableColumnStrcuture();
  }, [tableScorecardData, meeting]);



  const fetchProfileData = () => {
    let payload = {
      profileId: id,
      sectionId: Number(props?.menuId),
    };
    ServiceUtils.postRequest("kolProfile", payload).then(
      (response) => {
        if (response?.status === 200) {
          setImgSrc(response?.data?.data?.profile_picture)
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };

  const fetchCards = () => {
    let payload = {
      profileId: id
    };
    ServiceUtils.postRequest('tllScorecard', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setScoreCard(response?.data?.data?.dataJson);
          setCardContents({ ...response?.data?.data?.dataJson?.cardContent });
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }

  const openEdit = (record,index) => {
    setEditStatement({
      data: record?.podium_statement,
      id: record?.survey_response_id
    });
    setOpenEditModal(true);
    setPodiumRecord({
      record: record,
      index: index
    })
    // openModal(true);
  }

  const openModalEdit = (record,index) => {
    setPodiumEditStatement({
      data: record?.podium_statement,
      id: record?.survey_response_id
    });
    setOpenEditStatementModal(true);
    setPodiumRecord({
      record: record,
      index: index
    })
    // openModal(true);
  }

  const onSave = () => {
    fetchpodiumTableData();
  }

  const onEditSave = () => {
    fetchpodiumTableData();
  }

  const openModal = (open) => {
    return (
      <>
        {
          open &&
          <Modal
            title="Edit Statement"
            centered
            open={open}
            onCancel={() => openModal(false)}
            footer={[
              <Button key="submit" onClick={() => { openModal(false) }}>
                Cancel
              </Button>,
              <Button onClick={() => { onEditSave() }}>Save</Button>,
            ]}
          >
            <TextArea
              value={editStatement?.data.join('\n')}
              onChange={(e) => setPodiumEditStatement({ ...editStatement, data: e.target.value.split('\n') })}
              rows={6}
              style={{ width: '100%' }}
            />
          </Modal>
        }
      </>
    )

  }


  const fetchpodiumTableData = () => {
    let payload = {
      profileId: id
    };
    payload['type'] = openEditModal ? 'edit' : 'add';
    payload['statements'] = openEditModal ? editStatement?.data : [];
    payload['survey_response_id'] = openEditModal ? editStatement?.id : null;
    ServiceUtils.postRequest('podiumStatements', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let columns = response?.data?.data?.podium_noble_statements;
          if (openEditModal) {
            setOpenEditModal(false);
          };
          columns.headerContent.map((el) => {
            if (el.key === 'statement') {
              el.render = (statement) => {
                return (
                  <ul>
                    {statement.map((desc, ind) => (
                      <li key={ind}>{desc}</li>
                    ))}
                  </ul>
                );
              };
            }
            //  else if (el.key === 'action') {
            //   el.render = (_, record, index) => (
            //     <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            //       <div>
            //         <Button onClick={() => {
            //           openEdit(record)
            //         }} className="edit-meet-button" type="primary">
            //           Edit
            //         </Button>
            //       </div>
            //     </div>
            //   );
            // }
          }
          )
          if (openEditModal) {
            setOpenEditModal(false);
          }
          setPodiumData(columns);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }


  const fetchScoreCardData = () => {
    let payload = {
      profileId: id,
      advYear: defaultYear
    }
    ServiceUtils.postRequest('advScore', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setGuageChart(response?.data?.data?.scoreCard?.guage_chart_options);
          // setBarChart(response?.data?.data?.scoreCard?.horizantal_bar_options);
          const customRatings = response?.data?.data?.scoreCard?.horizantal_bar_options?.ratings;
          // const customRatings = {
          //   'Q1 2024': 'Rated 5 Stars',
          //   'Q2 2024': '',
          //   'Q3 2024': '',
          //   'Q4 2024': 'Rated 4 Stars'
          // };

          setBarChart({
            ...response?.data?.data?.scoreCard?.horizantal_bar_options,
            tooltip: {
              ...response?.data?.data?.scoreCard?.horizantal_bar_options.tooltip,
              formatter: function (params) {
                const yearLabel = params[0].name; // Y-axis data label (like "Q1 2024")
                const rating = customRatings[yearLabel] || 'No rating';
                return ` ${rating}`;
              }
            }
          });
          setYear(response?.data?.data?.scoreCard?.years);
          setDefaultYear(response?.data?.data?.scoreCard?.default_year);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }

  /**
   * To do individual save for each card data
   * @param {*} type  card type
   * @param {*} value edited value of textarea
   */
  const handleEditAndSave = (type, value) => {
    const filteredContent = value.filter(item => item.trim() !== ''); // Exclude empty strings
    let payload = {
      profileId: id,
      type: type,
      data: filteredContent
    }
    ServiceUtils.postRequest('updateCards', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          alertService.success('Saved successfully.')
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while saving the data.')
      }
    );
  };


  const routeToSurvey = () => {
    let payload = {
      'advYear': defaultYear,
      'profileId': id,
      'type': 'add'
    }
    ServiceUtils.postRequest('editAdvScore', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let payload = response?.data?.data?.survey_payload;
          let survey = response?.data?.data?.survey_name
          localStorage.setItem("surveyKeys", JSON.stringify({ 'survey': survey, 'surveyID': payload?.surveyID, 'surveyTemplateId': payload?.surveyTemplateId, "surveyResponseId": payload?.surveyResponseId }));
          navigate(response?.data?.data?.survey_url, { replace: true });
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const getAdvocacyScorecardList = () => {
    let payload = {
      'advYear': defaultYear,
      'profileId': id
    }
    ServiceUtils.postRequest('editAdvScore', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setOpenAdvScoreModal(true);
          setTableData({ ...response.data.data });
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const saveAdvScore = (_record) => {
    console.log(_record, "_record")
    let payload = {
      'advYear': defaultYear,
      'profileId': id,
      'type': 'edit',
      'survey_response_id': _record?.survey_response_id,
      'advScore': _record?.advocacy_score
    }
    ServiceUtils.postRequest('editAdvScore', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setOpenAdvScoreModal(false);
          fetchScoreCardData();
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching data.')
      }
    );
  }

  const changeColumnStrcuture = () => {
    let headerContent = [...tableData?.headerContent];
    let bodyContent = [...tableData?.bodyContent];
    let columns = JSON.parse(
      JSON.stringify(headerContent)
    );
    const handleChange = (value, key, record, index) => {
      let tempTableData = [...tableDataRef?.current?.bodyContent];
      tempTableData[index][key] = value;
      if (value > 5) {
        tempTableData[index][key] = "";
        alertService.warning('Advocacy Score must be between 1 to 5.');
        return
      }
      setTableData({
        ...tableData,
        bodyContent: tempTableData
      })
    }
    columns?.map((el) => {
      if (el.key === 'advocacy_score') {
        el.render = (_, record, index) => {
          return (
            <Input
              value={record?.advocacy_score}
              name="Advocacy Score"
              style={{ width: "90%" }}
              onChange={(e) => { handleChange(e.target.value, "advocacy_score", record, index) }}
              placeholder="Enter Advocacy Score"
            />
          );
        };
      } else if (el.key === 'action') {
        el.render = (_, record, index) => (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <Button onClick={() => {
                saveAdvScore(record)
              }} className="edit-meet-button" type="primary">
                Submit
              </Button>
            </div>
          </div>
        );
      }
    }
    );
    setColumnData(columns);
    setBodyData(bodyContent);
  }

  return (
    <>
      <div
        className="Tll-scorecard-root"
        data-test="Tll-scorecard-container"
      >
        <Layout>
          <Content>
            <Tabs
              style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
              defaultActiveKey="1"
              items={tabOptions}
              onChange={onChangeTab}
            />
          </Content>
        </Layout>
        {
                openEditStatementModal && <Modal
                  title="Edit Statement"
                  centered
                  open={openEditStatementModal}
                  onCancel={() => setOpenEditStatementModal(false)}
                  footer={[
                    // <Button key="submit" onClick={() => { setOpenEditModal(false) }}>
                    //   Cancel
                    // </Button>,
                    // onSave()
                    <Button onClick={() => { setOpenEditStatementModal(false) }}>Ok</Button>,
                  ]}
                >
                  <TextArea
                    value={podiumEditStatement?.data.join('\n')}
                    onChange={(e) => {
                      handleDropdownChange(
                        e.target.value,
                        "podium_statement",
                        "",
                        podiumRecord?.record,
                        podiumRecord?.index
                      )
                      setPodiumEditStatement({ ...podiumEditStatement, data: e.target.value.split('\n') })
                    }}
                    rows={6}
                    style={{ width: '100%' }}
                  />
                </Modal>
              }

      </div>
    </>
  );
};

export default TLLScoreCard;
