import { Card,Select,Table,Layout, Row, Col, Input, Space, Button, Divider, Collapse,  Checkbox,
    DatePicker, Tabs, Tooltip } from 'antd';
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MeetingFollowUp.scss'
import { alertService } from '../../../../Shared/Utils/ToasterUtils';
import { ServiceUtils } from '../../../../Shared/Utils/ServiceUtils';
import BreadcrumbList from '../../../../Shared/Components/Breadcrumb/Breadcrumb';
import classNames from 'classnames';
import { useSharedState } from '../../../../Shared/Services/WindowSizeService';
import { CaretRightOutlined, CloseOutlined, FilterOutlined } from "@ant-design/icons";
import {
    SearchOutlined
  } from "@ant-design/icons";
  import moment from "moment";
  import useWindowSize from '../../../../Shared/Utils/windowResizeUtils';
  const { RangePicker } = DatePicker;
  const {  Sider } = Layout;
const MeetingFollowUp = () => {
    const [hierarchy, setHierarchy] = useState([{ label: 'FollowUp', link: '' }]);
    const [tableData, setTableData] = useState({
        headerContent: [],
        bodyContent: []
    });
    const [defaultOptions, setDefaultOptions] = useState(['Meeting','Conference','General']);
    const dropdownOptions = [
        {
            label:"Meeting",
            value:"Meeting"
        },
        {
            label:"Conference",
            value:"Conference"
        },
        {
            label:"General",
            value:"General"
        }
    ];
    const [searchText, setSearchText] = useState("");
    const [filterOptionsActiveData, setFilterOptionsActiveData] = useState({});
    const [filterSelectedData, setFilterSelectedData] = useState({});
    const [showFilterFlag, setShowFilterFlag] = useState(true);


    const navigate = useNavigate();
    const windowSize$ = useSharedState();
    const { width } = useWindowSize();
    const isMobile = width <= 430;
    const sliderWidth = isMobile ? 150 : 250;



    useEffect(()=>{
        fetchTableData();
    },[defaultOptions]);

    const onFilterChange = (value) => {
        setDefaultOptions(value);
    };

    const fetchTableData = (filterJson) => {
        let payload = {
            filters: filterJson ? filterJson : {},
            searchText: searchText
        }
        ServiceUtils.postRequest("followups", payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setTableData(response?.data?.data?.tableData);
                    let filterres = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterData))
                    let filterPay = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterKeys))
                    // setFilterActiveDefaultData(filterPay);
                    getActiveFilterStructure(filterres, filterPay);
                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message);
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const filter = (input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
      };

      const handleKeypress = (e) => {
        if (e.key === "Enter") {
            fetchTableData();
        }
      };

      const getActiveFilterStructure = (data, reqObj) => {
        let datattt = data;
        let reqObject = reqObj;
    
        const onChangeFilters = (data, key) => {
          reqObject = { ...reqObject, [key]: data }
          setFilterSelectedData(reqObject);
        }
        datattt?.map((item) => {
          if (item.type === "checkBox") {
            console.log(item, "filter item");
            const getData = () => {
    
              return (
                [
                  <Checkbox.Group onChange={(e) => { onChangeFilters(e, item?.key) }} defaultValue={item?.defaultValue}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox value={option?.value} style={{ marginBottom: '3px' }}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Space>
                  </Checkbox.Group>
                ]
              )
            }
            item["children"] = getData();
          }
          else if (item.type === "date-picker") {
            const getCAlData = () => {
              return (
                <div style={{ paddingBottom: '5px' }}>
                  <RangePicker
                    // showTime={{
                    //   format: 'HH:mm',
                    // }}
                    // onChange={(e)=>{console.log(e)}}
                    defaultValue={[moment(...item?.defaultValue[0]), moment(...item?.defaultValue[1])]}
                    placeholder={["Start", "End"]}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value, dateString) => { onChangeFilters(dateString, item?.key) }}
                  // onOk={onOk}
                  />
                </div>
              )
            }
            item["children"] = getCAlData();
          }
        })
        setFilterOptionsActiveData(datattt);
      }

      const onChangeCollapse = (key) => {
        // console.log(key);
      };

      const clearAll = () => {
        // console.log()
        setShowFilterFlag(false);
      }

      const applyFilters = () => {
        fetchTableData(filterSelectedData);
      }

    return (
        <div className="MeetingFollowUp">
            <Card
                style={{ width: "100%", height: "fit-content" }}
                bodyStyle={{ padding: "10px" }}
            >
                <BreadcrumbList active={hierarchy} />
            </Card>
            <Layout>
                <div>
                    {
                        showFilterFlag ?
                            <Sider
                                width={sliderWidth}
                                style={{
                                    overflow: 'visible'
                                }}
                            >
                                <Layout style={{ height: '-webkit-fill-available' }}>
                                    <Header className="survey-filter-header" style={{ "padding": "0px !important" }}>
                                        <div className="demo-logo-vertical" />
                                        <Row gutter={16} style={{ padding: '0px', margin: '0px' }}>
                                            <Col span={12}>
                                                <b>Filters</b>
                                            </Col>
                                            <Col span={12} style={{ alignSelf: 'center' }}>
                                                <span style={{ opacity: '0.5', display: 'flex', justifyContent: 'end' }} onClick={clearAll} className="pointer">
                                                    <CloseOutlined />
                                                </span>
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </Header>
                                    <Layout>
                                        <Content className="survey-filter-content">
                                            <Collapse
                                                defaultActiveKey={['1']}
                                                onChange={onChangeCollapse}
                                                expandIconPosition='end'
                                                items={filterOptionsActiveData}
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            />
                                        </Content>
                                    </Layout>
                                    <Footer>
                                        <div className="submit-block" style={{ marginBottom: '5px' }}>
                                            <div style={{ paddingRight: "10px" }}>
                                                <Button
                                                    className="edit-meet-button"
                                                    type="primary"
                                                    onClick={() =>
                                                        applyFilters()
                                                    }
                                                >
                                                    Apply
                                                </Button>
                                                {/* onClick={onResetFilters} */}
                                                <Button style={{ 'marginLeft': '5px' }}>Reset</Button>
                                            </div>
                                        </div>
                                    </Footer>
                                </Layout>

                            </Sider> :
                            <div>
                                <Tooltip title="Filters">
                                    <Button style={{ margin: '15px' }} onClick={() => { setShowFilterFlag(true) }}>

                                        <FilterOutlined />
                                    </Button>
                                </Tooltip>
                                <Sider width={'5%'} />
                            </div>
                    }
                </div>
            <Content>
                <div className={classNames("", {
                    "follow-container":
                        windowSize$ === "lg" ||
                        windowSize$ === "xl" ||
                        windowSize$ === "xxl",
                    "follow-container-small": windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
                })}>
                    <Table
                        columns={tableData?.headerContent}
                        dataSource={tableData?.bodyContent}
                        bordered
                        pagination={false}
                        scroll={{
                            y: "calc(100vh - 270px)",
                        }}
                    />
                </div>
            </Content>
            </Layout>
        </div>
    )
}

export default MeetingFollowUp;