import React, { useEffect, useState, useMemo, useRef } from "react";
import "./MeetingDetails.scss";
import { Row, Col, Button, Typography, Card, Select, Upload,Space,Drawer, Form, Input, DatePicker,Tooltip, Menu , Divider, Checkbox } from "antd";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { Calendar, momentLocalizer, dateFnsLocalizer } from "react-big-calendar";
import { getDecryptSession } from "../../../Shared/Utils/CommonUtils";
import { SelectOutlined, UserSwitchOutlined, SyncOutlined,LeftOutlined, CaretDownOutlined ,RightOutlined,CalendarOutlined, CheckCircleTwoTone ,FilterOutlined, PlusOutlined, ClearOutlined, UserOutlined} from "@ant-design/icons";
import warning from "antd/lib/_util/warning";
import PropTypes from 'prop-types';
import { EditFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import "react-big-calendar/lib/css/react-big-calendar.css";
import SyncMeeting from "./SyncMeeting/SyncMeeting";
import MenuItem from "antd/es/menu/MenuItem";
import { Option } from "antd/es/mentions";
import useWindowSize from "../../../Shared/Utils/windowResizeUtils";
import Agenda from "./Agenda";
import {format,parse, startOfWeek, getDay} from "date-fns";
import enUS from 'date-fns/locale/en-US';
import 'moment-timezone';

export default function MeetingDetails() {
    const { Title, Text } = Typography;
    const windowSize$ = useSharedState();
    // console.log("test", windowSize$);
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    const getUserDetails = () => {
        const session = localStorage.getItem("arcutis-user");
        const userDetails = getDecryptSession(session);
        // console.log(userDetails);
        return userDetails;
    }


    const navigate = useNavigate();
    const { id } = useParams();
    const localizer = momentLocalizer(moment);
    const locales = {
        'en-US': enUS,
      }
    // const localizer = dateFnsLocalizer({
    //     format,
    //     parse,
    //     startOfWeek,
    //     getDay,
    //     locales,
    //   });
    const [meetingList, setMeetingData] = useState({});
    const [attendeeList, setAttendeeListData] = useState([]);
    const [objectiveList, setObjectiveList] = useState([]);
    const [objectiveValue, setObjectiveValue] = useState([]);
    const [followUpList, setFollowUpList] = useState([]);
    const [followUpValue, setFollowUpValue] = useState([]);
    const [kolList, setKolList] = useState([]);
    const [kolValue, setKolValue] = useState([]);
    const [meetingType, setMeetingTypeData] = useState([]);
    const [attendeeFilterType, setAttendeeFilterTypeData] = useState([]);
    const [filterType, setFilterTypeData] = useState([]);
    const [defaultFilter, setDefaultFilterData] = useState({});
    const [defaultView, setDefaultViewData] = useState('day');
    const view = useRef();
    view.current = defaultView;
    const [userDetails, setUserDetails] = useState(getUserDetails());
    const [syncinputs, setSyncInputs] = useState();
    const [open, setOpen] = useState(false);
    const [filterFlag, setFilterFlag] = useState(false);
    const [openSync, setOpenSync] = useState(false);
    const [syncMeetingData, setSyncMeetingData] = useState();
    const [currentDate, setCurrentDate] = useState(moment().startOf('day'));
    const [goToConferenceStartDate,setGoToConferenceDate] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const currentView  = useRef();
    currentView.current = defaultView;
    const currentDateRef  = useRef();
    currentDateRef.current = currentDate || new Date();
    const convertDate = (date) => {
        return new Date(date);
    };
    const [events, setEvents] = useState();
    const [defaultTimezone,setDefaultTimezone] = useState('');
    const [options,setOptions] = useState([]);
    const [selectedZone,setSelectedZone] = useState('');
    

    const columns = [
        {
            label: "Meeting List",
            link: "",
        },
    ];

    // function Event({ event }) {
    //     return (
    //       <span>
    //         <strong>{event.title}</strong>
    //         {event.desc && ':  ' + event.desc}
    //       </span>
    //     )
    //   }
    //   Event.propTypes = {
    //     event: PropTypes.object,
    //   }

    useEffect(() => {
        // Optionally log or handle any changes to current date here
      }, [currentDate]);
    


    const  navjj = () => {
        // console.log(userDetails?.admin_access);
        if(userDetails?.admin_access){
            localStorage.setItem("editMeet",true);
        }
    }
    const  EventAgenda = ({ event,title }) => {
        // console.log(userDetails?.admin_access);
        // console.log(event)
        return (
            <div className='flex justify-content-center' onClick={()=>{navjj()}}>
                <div>
                    <em >{event.title}</em>
                    <p>{event.desc}</p>
                </div>
                {userDetails?.admin_access &&
                    <div onClick={()=>{navigate('home');}}>
                        <EditFilled style={{ right: '30px' }}/>
                    </div>
                }
            </div>
        )
    }
   


    const handleEnablePopover = (event) => {
        // console.log(event);
        return (
          <div>
            <Row>
              <Col 
                xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%"}}
                lg={{ flex: "100%" }}
                xl={{ flex: "100%" }}
              >
                {/* <span>Meeting Name:  */}
                    <p style={{fontSize:"14px"}}>{event.title}</p>
                {/* </span> */}
              </Col>
            </Row>
            <Row>
            <Col 
                xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%"}}
                lg={{ flex: "100%" }}
                xl={{ flex: "100%" }}
                >
                {/* <span>Timings:  */}
                    <p style={{fontSize:"12px"}}>{event?.meeting_timings}</p>
                {/* </span> */}
              </Col>
            </Row>
          </div>
        );
      }

      const CustomHeaderWrapper = (props) => {
            return <CustomHeader {...props} currentDate={currentDate} defaultView={currentView.current}/>;
      };

      const handleNavigate = (action,date) => {
        if (action === 'TODAY') {
          setCurrentDate(moment().startOf('day'));
        }else  if (action === 'DATE') {
            setCurrentDate(new Date(date));
        }else if (action === 'PREV') {
            setCurrentDate(prevDate => moment(prevDate).subtract(1, currentView.current).toDate());
        } else if (action === 'NEXT') {
            setCurrentDate(prevDate => moment(prevDate).add(1, currentView.current).toDate());
        } 
      };
      
      const TimeGutterHeaderWrapper = (props) => {
        return view.current === 'day' ? <CustomTimeHeader {...props} /> : null;
    };

    const handleTimeZoneChange = (props) => {
        let info = JSON.parse(localStorage.getItem('conferenceData'));
        info['userTimezone'] = props;
        conferenceInfo(info);
        setSelectedZone(info['userTimezone']);
        localStorage.setItem('selectedTimeZone',JSON.stringify(info['userTimezone']))
    }

      EventAgenda.propTypes = {
        event: PropTypes.object,
        }
    
        const eventAgendaFun = (evn) => {
        // console.log(evn)
        }
    const AgendaDate = () => <></>;
    const AgendaTime = () => <></>;
    const { components, defaultDate } = useMemo(
        () => (
            {
                components: {
                    agenda:undefined,
                    event: eventAgendaFun(),
                    toolbar:(props)=><CustomToolBar handleNavigate={handleNavigate} handleTimeZoneChange={handleTimeZoneChange} currentDate={currentDateRef} {...props} updateView={handleViewChange} options={options} defaultValue={defaultTimezone}/>,
                    // header: (props)=>CustomHeaderWrapper(props),
                    // day: {
                    //     header: (props)=>CustomHeaderWrapper(props),
                    //   },
                    timeGutterHeader: (props) => <TimeGutterHeaderWrapper {...props} currentDate={currentDateRef} />,
                    day: { header: (props)=>CustomHeaderWrapper(props) },
                    week: { header: (props)=>CustomHeaderWrapper(props) }, 
                    month: { header: (props)=>CustomHeaderWrapper(props) }, 
                    eventWrapper: ({ event, children }) => (
                        <div
                        onMouseOver={
                            e => {
                            e.preventDefault();
                            }
                        }
                        >
                        <Tooltip placement="top" title={handleEnablePopover(event)}>
                            {children}
                        </Tooltip>
                        </div>
                    )
          },
            }

        ),
        [options,defaultTimezone]
      )


    useEffect(() => {
        localStorage.setItem("editMeet",false);
        let filter = JSON.parse(localStorage.getItem('meetingListFilter'));
        if(filter?.attendeeFilter?.length || filter?.meetingFilter?.length || filter?.objectiveFilter
             || filter?.kolFilter || filter?.followUpFilter
        ){
            filter.calenderDate = new Date(filter?.calenderDate) || new Date();
            setAttendeeFilterTypeData(filter?.attendeeFilter);
            setDefaultFilterData(filter);
            setCurrentDate(filter.calenderDate)
            conferenceInfo({
                conference_id: parseInt(id),
                attendee_id: filter?.attendeeFilter,
                type_id: filter?.meetingFilter,
                kol_id: filter?.kolFilter,
                objective_id: filter?.objectiveFilter,
                followUp_id: filter?.followUpFilter
            });
            setFilterTypeData(filter?.meetingFilter);
            setObjectiveValue(filter?.objectiveFilter);
            setKolValue(filter?.kolFilter);
            setFollowUpValue(filter?.followUpFilter);
        }
        else{
            let temp = {
                "meetingFilter": [],
                "attendeeFilter": [
                ],
                "objectiveFilter":[],
                "kolFilter":[],
                "followUpFilter":[],
                "calenderDate": filter?.calenderDate ? new Date(filter?.calenderDate) : new Date(Date.now()),
                "view": {}
            }
            setDefaultFilterData(temp);
            setCurrentDate(temp['calenderDate'])
        }
        meetingFilter();
        // listFilters();
    }, [goToConferenceStartDate]);

    const conferenceInfo = (payload) => {
        const session = localStorage.getItem("arcutis-user");
        const userDetails = getDecryptSession(session);
        payload["user_id"] = userDetails.user_id;
        payload["userTimezone"] = payload?.userTimezone ? payload?.userTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
        ServiceUtils.postRequest("conferenceInfo", payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setMeetingData(response.data.data);
                    let scheduleData = response.data.data?.eng_schedule_all;
                    scheduleData?.map((sch, index) => {
                        scheduleData[index].start = convertDate(scheduleData[index].start);
                        scheduleData[index].end = convertDate(scheduleData[index].end);
                    });
                    const now = new Date();
                    const filteredEvents = scheduleData.filter(event => event.end >= now);
                    setEvents(scheduleData);
                    if(showFilters) {
                        setFilterFlag(true);
                    } else {
                        setFilterFlag(false);
                    }
                    let start = new Date(response?.data?.data?.conference_start_date);
                    let end = new Date(response?.data?.data?.conference_end_date);
                    let curr = new Date();
                    if (curr >= start && curr <= end) {
                        setCurrentDate(curr);
                    } else {
                        setCurrentDate(start);
                    }
                    // setCurrentDate(new Date(response?.data?.data?.conference_start_date));
                    
                    //setting up local storage with current filter values
                    // Step 1: Retrieve the existing object from local storage
                    const existingData = localStorage.getItem('meetingListFilter');

                    // Step 2: Parse the JSON string into a JavaScript object
                    let meetingListFilter = existingData ? JSON.parse(existingData) : {};

                    // Step 3: Update the specific properties
                    meetingListFilter.meetingFilter = payload["type_id"];
                    meetingListFilter.attendeeFilter = payload["attendee_id"];
                    meetingListFilter.objectiveFilter = payload["objective_id"];
                    meetingListFilter.kolFilter = payload["kol_id"];
                    meetingListFilter.followUpFilter = payload["followUp_id"];

                    // Step 4: Stringify the updated object and save it back to local storage
                    localStorage.setItem('meetingListFilter', JSON.stringify(meetingListFilter));

                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message);
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const listOfTimezones = () => {
        let payload = {
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        ServiceUtils.postRequest("listOfTimezones", payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setOptions(response?.data?.data?.timezone_list);
                    setSelectedZone(response?.data?.data?.defaultValue);
                    setDefaultTimezone(response?.data?.data?.defaultValue);
                    localStorage.setItem('selectedTimeZone',JSON.stringify(response?.data?.data?.defaultValue));
                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message);
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const session = localStorage.getItem("arcutis-user");
    const user = getDecryptSession(session);

    const onResetFilters = () => {
        resetFilters();
    }

    const handleUserId = () => {
        let bool = false;    
        for (let i = 0; i < attendeeList.length; i++) {
            if([attendeeList[i].value].includes(userDetails?.user_id)) {
                setAttendeeFilterTypeData([parseInt(userDetails?.user_id)]);
                bool = true;
            }
        }
        if(bool == false) {
            alertService.warning("LoggedIn user has no meeting");
        }
    }

    const resetFilters = () => {
        let payLoad = {
            conference_id: parseInt(id),
        };
        ServiceUtils.postRequest("meetingFilters",payLoad).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    let tempMeetingTypes= JSON.parse(JSON.stringify(response.data.data.defaultData));
                    setAttendeeFilterTypeData(tempMeetingTypes?.attendeeFilter);
                    setFilterTypeData(tempMeetingTypes?.typeFilter);
                    setKolValue(tempMeetingTypes?.kols);
                    setObjectiveValue(tempMeetingTypes?.objectives);
                    setFollowUpValue(tempMeetingTypes?.followUps);
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    }

    const attendeeFilter = (meetingData) => {
        let payLoad = {
            conference_id: parseInt(id),
        };
        ServiceUtils.postRequest("attendeeFilter",payLoad).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    let tempAttendeeFilter= JSON.parse(JSON.stringify(response.data.data));
                    setAttendeeListData(tempAttendeeFilter);
                    let defaultFilterData = JSON.parse(JSON.stringify(response?.data?.defaultData));
                    let defaultFilterId = [];
                    if (defaultFilterData && defaultFilterData.length > 0) {
                        for (let i = 0; i < defaultFilterData?.length; i++) {
                            if (defaultFilterData[i].value !== "all") {
                                defaultFilterId.push(defaultFilterData[i].value)
                            }
                         }
                        setAttendeeFilterTypeData(defaultFilterId)
                        listFilters({
                            conference_id: parseInt(id),
                            attendee_id: defaultFilterId,
                            type_id: meetingData ? meetingData : []
                        })
                    } 
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const meetingFilter = () => {
        let payLoad = {
            conference_id: parseInt(id),
        };
        ServiceUtils.postRequest("typeFilter",payLoad).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    let tempMeetingTypes= JSON.parse(JSON.stringify(response.data.data))
                    setMeetingTypeData(tempMeetingTypes);
                    let defaultMeetingFilter = JSON.parse(JSON.stringify(response?.data?.defaultData));
                    let defaultMeetingFilterId = [];
                    if (defaultMeetingFilter && defaultMeetingFilter.length > 0) {
                        for (let i=0; i<defaultMeetingFilter.length;i++) {
                            defaultMeetingFilterId.push(defaultMeetingFilter[i])
                        }
                        setFilterTypeData(defaultMeetingFilterId)
                    }
                    attendeeFilter(defaultMeetingFilterId);
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    };

    const listFilters = (data) => {
        let payLoad = {
            conference_id: parseInt(id),
        };
        ServiceUtils.postRequest("meetingFilters",payLoad).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    objectiveFilterList(response?.data?.data,data);
                    listOfTimezones();
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        );
    }

    const objectiveFilterList = (response,data) => {
        let list = JSON.parse(JSON.stringify(response?.objectives?.data));
        setObjectiveList(list);
        let defaultObjectiveFilter = JSON.parse(JSON.stringify(response?.objectives?.defaultData));
        let defaultObjectiveFilterId = [];
        if (defaultObjectiveFilter && defaultObjectiveFilter.length > 0) {
            for (let i=0; i<defaultObjectiveFilter.length;i++) {
                if (defaultObjectiveFilter[i].value !== "all") {
                    defaultObjectiveFilterId.push(defaultObjectiveFilter[i].value)
                }
            }
            setObjectiveValue(defaultObjectiveFilterId)
        }
        followUpFilterList(response,data);
    }

    const followUpFilterList = (response,data) => {
        let list= JSON.parse(JSON.stringify(response?.followUps?.data))
        setFollowUpList(list);
        let defaultfollowUpFilter = JSON.parse(JSON.stringify(response?.followUps?.defaultData));
        let defaultfollowUpFilterId = [];
        if (defaultfollowUpFilter && defaultfollowUpFilter.length > 0) {
            for (let i=0; i<defaultfollowUpFilter.length;i++) {
                if (defaultfollowUpFilter[i].value !== "all") {
                    defaultfollowUpFilterId.push(defaultfollowUpFilter[i].value)
                }
            }
            setFollowUpValue(defaultfollowUpFilterId)
        }
        kolsFilterList(response,data);
    }

    const kolsFilterList = (response,data) => {
        let list= JSON.parse(JSON.stringify(response?.kols?.data))
        setKolList(list);
        let defaultKolsFilter = JSON.parse(JSON.stringify(response?.kols?.defaultData));
        let defaultKolsFilterId = [];
        if (defaultKolsFilter && defaultKolsFilter.length > 0) {
            for (let i=0; i<defaultKolsFilter.length;i++) {
                if (defaultKolsFilter[i].value !== "all") {
                    defaultKolsFilterId.push(defaultKolsFilter[i].value)
                }
            }
            setKolValue(defaultKolsFilterId)
        }
        data['kol_id'] = kolValue;
        data['objective_id'] = objectiveValue;
        data['followUp_id'] = followUpValue;
        localStorage.setItem('conferenceData',JSON.stringify(data));
        conferenceInfo(data);
    }

    const onCalenderSelect = (meetingData) => {
        // console.log(meetingData)
        // console.log(localStorage.getItem("editMeet"));
        if(localStorage.getItem("editMeet")==="true"){
        }
        else{
            localStorage.setItem("editMeet",false);
        }
        localStorage.setItem("meetingListFilter", JSON.stringify({
            meetingFilter: filterType,
            attendeeFilter: attendeeFilterType,
            objectiveFilter:objectiveValue,
            kolFilter:kolValue,
            followUpFilter:followUpValue,
            calenderDate: meetingData.start,
            view: defaultView,
            conferenceId: Number(id)
        }));
        localStorage.setItem("meetingTitle", JSON.stringify(meetingData.title));
        localStorage.setItem(
            "meeting_host",
            JSON.stringify(meetingData.meeting_host)
        );
        localStorage.setItem(
            "meetingDates",
            JSON.stringify({
                start_date: meetingData.start,
                end_date:meetingData.end,
                timezone:selectedZone
            })
        );
        navigate("engagement/" + meetingData.id);
    };

    const handleAttendeeChangeFilter = (va) => {
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (let i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < attendeeList.length; i++) {
                    if (attendeeList[i].value !== "all") {
                        checkAllOption.push(attendeeList[i].value)
                        checkAllOptions.push(attendeeList[i])
                    }
                }
            } 
        }
        if(checkAllBoolean){
            setAttendeeFilterTypeData(checkAllOption)
        }
        else{
            setAttendeeFilterTypeData(va);
        }
    }

    const selectAllAttendees = () => {
        let checkAllOption = [];
        for (let i = 0; i < attendeeList.length; i++) {
            if (attendeeList[i].value !== "all") {
                checkAllOption.push(attendeeList[i].value)
            }
        }
        setAttendeeFilterTypeData(checkAllOption)
    }

    const handleMeetingChangeFIlter = (va) => {
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (let i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < meetingType.length; i++) {
                    if (meetingType[i].value !== "all") {
                        checkAllOption.push(meetingType[i].value)
                        checkAllOptions.push(meetingType[i])
                    }
                }
            } 
        }
        if(checkAllBoolean){
            setFilterTypeData(checkAllOption)
            // console.log(checkAllOption);
        }
        else{
            setFilterTypeData(va);
        }
    }

    const handleObjectives = (va) => {
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (let i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < objectiveList.length; i++) {
                    if (objectiveList[i].value !== "all") {
                        checkAllOption.push(objectiveList[i].value)
                        checkAllOptions.push(objectiveList[i])
                    }
                }
            } 
        }
        if(checkAllBoolean){
            setObjectiveValue(checkAllOption)
            // console.log(checkAllOption);
        }
        else{
            setObjectiveValue(va);
        }
        // setAttendeeFilterTypeData(va);  
    }

    const selectAllObjectives = () => {
        let checkAllOption = [];
        for (let i = 0; i < objectiveList.length; i++) {
            if (objectiveList[i].value !== "all") {
                checkAllOption.push(objectiveList[i].value)
            }
        }
        setObjectiveValue(checkAllOption)
    }


    const handleFollowUps = (va) => {
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (let i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                for (i = 0; i < followUpList.length; i++) {
                    if (followUpList[i].value !== "all") {
                        checkAllOption.push(followUpList[i].value)
                        checkAllOptions.push(followUpList[i])
                    }
                }
            } 
        }
        if(checkAllBoolean){
            setFollowUpValue(checkAllOption)
        }
        else{
            setFollowUpValue(va);
        }
    }

    const selectAllFollowUps = () => {
        let checkAllOption = [];
        for (let i = 0; i < followUpList.length; i++) {
            if (followUpList[i].value !== "all") {
                checkAllOption.push(followUpList[i].value)
            }
        }
        setFollowUpValue(checkAllOption)
    }


    const handleKols = (va) => {
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (let i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                for (i = 0; i < kolList.length; i++) {
                    if (kolList[i].value !== "all") {
                        checkAllOption.push(kolList[i].value)
                        checkAllOptions.push(kolList[i])
                    }
                }
            } 
        }
        if(checkAllBoolean){
            setKolValue(checkAllOption)
        }
        else{
            setKolValue(va);
        }
    }

    const selectAllKols = () => {
        let checkAllOption = [];
        for (let i = 0; i < kolList.length; i++) {
            if (kolList[i].value !== "all") {
                checkAllOption.push(kolList[i].value)
            }
        }
        setKolValue(checkAllOption)
    }



    const handleAttendeeChange = (value) => {
        setAttendeeFilterTypeData(value);
        conferenceInfo({
            conference_id: parseInt(id),
            attendee_id: value,
            type_id: filterType,
        });
    };

    const handleMeetingChange = (value) => {
        setFilterTypeData(value);
        conferenceInfo({
            conference_id: parseInt(id),
            attendee_id: attendeeFilterType,
            type_id: value,
        });
    };

    const eventPropGetter = (event, start, end, isSelected) => {
        const currentDate = new Date();
        const eventDate = new Date(start);
        
        // Check if the event date is today
        const isToday = currentDate.getFullYear() === eventDate.getFullYear() &&
            currentDate.getMonth() === eventDate.getMonth() &&
            currentDate.getDate() === eventDate.getDate();
    
        // Set the spring color dynamically
        // event.backgroundColor ||
        const springColor =  event.backgroundColor || '#9b59b6'; // Default color if event.backgroundColor is not provided
    
        return {
            className: isToday ? '' : 'spring-border', 
            style: {
                color: event.color,
                backgroundColor: '#e0e4fc',
                borderTop: 0,
                borderRight: 0,
                borderBottom: 0,
                borderRadius: '0px !important',
                paddingLeft: '10px', // Reduced padding to make space for a narrower border
                backgroundImage: !isToday
                    ? `linear-gradient(
                        -5deg, /* Angle for the slant */
                        ${springColor} 85%, /* Dynamic stripe color */
                        transparent 6.5%, /* Space between stripes */
                        transparent 50%, 
                        ${springColor} 90%, 
                        ${springColor} 85%, 
                        transparent 85%, 
                        transparent
                    )`
                    : 'none',
                backgroundSize: '4px 8px', 
                backgroundRepeat: 'repeat-y', 
                backgroundPosition: 'left center', 
            },
            allDayMaxRows: {
                type: 1,
            },
        };
    };
    


    const formats = {
        dateFormat: "DD", // Format for the day of the month
        dayFormat: "dddd", // Format for the day of the week (e.g., "Mon", "Tue", etc.)
        timeGutterFormat: (date, culture, localizer) => 
            localizer.format(date, 'hh:mm A', culture), // Custom time format
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, 'DD MMM', culture) + ' - ' + localizer.format(end, 'DD MMM', culture), // Custom format for the range of dates in the header
        weekdayFormat: 'dddd', // Format for the day of the week in the month view
        monthHeaderFormat: 'MMMM YYYY', // Format for the month and year in the header
        dayHeaderFormat: 'dddd, MMM DD', // Format for the day and month in the day header
        dayRangeHeaderContentRenderer: ({ start, end, label }) => label, // Custom content renderer for the range of dates in the header  
        agendaHeaderFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, 'MM/DD/YYYY', culture) +
            ' - ' +
            localizer.format(end, 'MM/DD/YYYY', culture),           
    };

    const filterOption = (input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    const filter = (input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    const addMeeting = (slots) => {
        navigate(`engagement/addMeeting?startdate=${currentDate}`);
        // navigate('/home/meetingDetails/engagement/addMeeting')
    };

    const navigateWithProps=(slot)=>{
        const { start, end } = slot;
        // /home/meetingDetails/2/engagement/addMeeting?startdate=2024-06-02T18:30:00.000Z
        const formattedStart = encodeURIComponent(moment(start).toISOString());
        const formattedEnd = encodeURIComponent(moment(end).toISOString());
        navigate(`engagement/addMeeting?startdate=${formattedStart}&&enddate=${formattedEnd}`);
        // navigate(`/home/meetingDetails/${id}/engagement/addMeeting?startdate=${start}&enddate=${end}`);
    }

    const handleViewChange = (newView) => {
        setDefaultViewData(newView);
        view.current = newView;
    };

    const props = {
        name: 'file',
        action: "http://localhost:3000/addMeetingsBulk",
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
        //   if (info.file.status !== 'uploading') {
        //     console.log(info.file, info.fileList);
        //   }
          if (info.file.status === 'done') {
            alert("File uploaded successfully"); 
          } else if (info.file.status === 'error') {
            warning("File upload failed"); 
          }
        },
      };

    const handleFileDownload = async () => {
        try {
            // Make the API request to fetch the file data
            const response = await ServiceUtils.getBlobRequest("getTemplateAddMeetingsBulk");
            if (response && response.data) {
                const data = new Blob([response.data], { type: "" });
                var csvURL = window.URL.createObjectURL(data);
                var tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", "filename.xlsx");
                tempLink.click();
            } 
            else if(response.status === 401 ){
                navigate('/logout');
            }
            else {
                alert("File data not found in response");
            }
        } catch (error) {
            console.error("Error while downloading file:", error);
            alert("Error while downloading file");
        }
    };
    const navigateSyncPage = () => {
        // localStorage.setItem('history', '/home/syncMeeting');
        // navigate("/home/syncMeeting")
        setOpen(true);
    }

    const openFilter = () => {
        setFilterFlag(true);
    }

    const onCloseFilter = () => {
        setFilterFlag(false);
        setShowFilters(false);
    }

    const handleCalender = (event, val, key) => {
        // console.log(event)
        // console.log(val)
        const name = key;
        const value = val;
        // console.log(val);
        setSyncInputs((values) => ({ ...values, [name]: value }))
    }

    const onClose = () => {
        setOpen(false);
    }

    const validateInputs = () => {
        if(syncinputs?.start_date && syncinputs?.end_date){
            return true;
        }
        else{
            return false;
        }
    }

    const getEventSync = () => {
        let payload = {
            "start_time": syncinputs?.start_date,
            "end_time": syncinputs?.end_date,
        }
        // console.log(payload);
        ServiceUtils.postRequest("getEvents", payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // console.log(response.data);
                    setSyncMeetingData(response?.data?.data);
                    setOpen(false);
                    setOpenSync(true);
                    // alertService.success(response?.data?.message);
                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message);
                }
            },
            (error) => {
                alertService.error("Error while fetching Data.");
            }
        ); 
    }

    const onSubmit = () => {
        if (validateInputs()) {
            getEventSync()
        }
        // console.log(syncinputs)
    };

    const onSubmitFilters = () => {
        setShowFilters(false);
        let attendee_id = attendeeLists();
        let type_id = typeLists();
        let kol_id = kolLists();
        let objective_id = objectiveLists();
        let followUp_id = followUpLists();
        localStorage.setItem('conferenceData',JSON.stringify({
            'conference_id': parseInt(id),
            'attendee_id':attendee_id,
            'type_id': type_id,
            'kol_id':kol_id,
            'objective_id':objective_id,
            'followUp_id':followUp_id
                }))
    

        conferenceInfo({
           'conference_id': parseInt(id),
            'attendee_id':attendee_id,
            'type_id': type_id,
            'kol_id':kol_id,
            'objective_id':objective_id,
            'followUp_id':followUp_id
        });
    }

    const attendeeLists = () => {
        let list = [];
        attendeeFilterType?.map(item => {
            if(typeof(item) === 'object' && item?.hasOwnProperty('value') && item?.hasOwnProperty('label')) {
                list.push(item['value'])
            } else {
                list.push(item);
            }
        })
        return list;
    }

    const typeLists = () => {
        let list = [];
        filterType?.map(item => {
            if(typeof(item) === 'object' && item?.hasOwnProperty('value') && item?.hasOwnProperty('label')) {
                list.push(item['value'])
            } else {
                list.push(item);
            }
        })
        return list;
    }

    const kolLists = () => { 
        let list = [];
        kolValue?.map(item => {
            if(typeof(item) === 'object' && item?.hasOwnProperty('value') && item?.hasOwnProperty('label')) { 
                list.push(item['value'])
            } else {
                list.push(item);
            }
        });
        return list;
    }

    const objectiveLists = () => {
        let list = [];
        objectiveValue?.map(item => {
            if(typeof(item) === 'object' && item?.hasOwnProperty('value') && item?.hasOwnProperty('label')) {
                list.push(item['value'])
            } else {
                list.push(item);
            }
        });
        return list;
     }

    const followUpLists = () => { 
        let list = [];
        followUpValue?.map(item => {
            if(typeof(item) === 'object' && item?.hasOwnProperty('value') && item?.hasOwnProperty('label')) {
                list.push(item['value'])
            } else {
                list.push(item);
            }
        });
        return list;
    }

    const onCloseSync = (event) => {
        setOpenSync(event)
        setSyncInputs();
    }
    const handleAgendaMonthChange = (action) => {
            const currentDate = new Date(currentDateRef?.current);
            if(action === 'Next'){
            currentDate.setMonth(currentDate.getMonth() + 1); 
            handleNavigate('NEXT', currentDate);
            setCurrentDate(prevDate => moment(prevDate).add(1, 'month').toDate());
            }else{
                currentDate.setMonth(currentDate.getMonth() - 1); 
                handleNavigate('PREV', currentDate);
                setCurrentDate(prevDate => moment(prevDate).subtract(1, 'month').toDate());
            }
        };
        

        const defaultValues = [1,4,8]


    return (
        <div className="details-root" data-test="details-container">
            { !openSync ?
                <>
                    <div className="container">
                        <Card
                            style={{ width: "100%", height: "fit-content" }}
                            bodyStyle={{ padding: "10px" }}
                        >
                            <BreadcrumbList active={columns} />
                        </Card>
                        <Card className="agendaCard">
                            <div className="detailsList">
                                <Row gutter={[16, 16]} style={{ display: 'flex',justifyContent: 'space-between',alignItems:"center"}}>
                                    <Col
                                        className={classNames("", {
                                            "class-33":
                                                windowSize$ === "lg" ||
                                            windowSize$ === "xl" ||
                                                windowSize$ === "xxl",
                                            "width-100": windowSize$ === "sm" ,
                                        })}
                                    >
                                        <Title level={5} style={{ margin: "0px" }}>
                                            {meetingList.conference_name}
                                            {/* {userDetails.admin_access && windowSize$ !== "xs" && (
                                                <Button
                                                    style={{
                                                        background: "#E0B41C",
                                                        marginTop: "5px",
                                                        marginLeft: "5px",
                                                        position: "fixed",
                                                        right: "15px",
                                                    }}
                                                    type="primary"
                                                    onClick={() => addMeeting()}
                                                >
                                                    Add
                                                </Button>
                                            )} */}
                                        </Title>
                                    </Col>
                                        <Col
                                            className={classNames("", {
                                                "class-33":
                                                    windowSize$ === "lg" ||
                                                    windowSize$ === "xl" ||
                                                    windowSize$ === "xxl",
                                                "width-100":
                                                    windowSize$ === "sm" ||
                                                    windowSize$ === "md" ||
                                                    
                                                    windowSize$ === "xs", })} 
                                        >
                                            <div style={{ float: "right" , display: "flex"}} >
                                                    {/* <div style={{ marginRight:"8px"}}>
                                                        <Upload {...props} accept=".xls, .xlsx" maxCount={1}>
                                                            <Button><UploadOutlined /></Button>
                                                        </Upload>
                                                    </div> */}
                                                    {/* <div style={{ marginRight:"8px"}}> 
                                                        <Button
                                                            onClick={handleFileDownload}
                                                        >
                                                            <DownloadOutlined />
                                                        </Button>
                                                    </div> */}
                                                     <div style={{ marginRight:"10px"}}>
                                                        {/* {userDetails.admin_access && windowSize$ === "xs" && ( */}
                                                        <Tooltip title={"Click to apply the filters"}>
                                                            <Button
                                                                onClick={() => {openFilter()}}
                                                                style={{fontWeight:"500"}}
                                                                className="btn-border"
                                                            >
                                                                    <Space><FilterOutlined />{!isMobile&&'Filter'}</Space>
                                                            </Button>
                                                        </Tooltip> 
                                                        {/* )} */}
                                                    </div>
                                                    {userDetails?.role_access?.home?.conferences?.edit && <div style={{ marginRight:"10px"}}>
                                                        <Tooltip title={"Click to synchronize the calender"}>
                                                            <Button
                                                                onClick={() => {navigateSyncPage()}}
                                                                style={{fontWeight:"500"}}
                                                                className='btn-border'
                                                            >
                                                                    <Space style={{columnGap:'4px'}}><SyncOutlined /> {!isMobile&&'Sync'}</Space>
                                                            </Button>
                                                        </Tooltip> 
                                                    </div>}
                                                    {!isMobile &&<div style={{ marginRight:"10px"}}>
                                                        {/* {userDetails.admin_access && windowSize$ === "xs" && ( */}
                                                        <Tooltip title={"Go to Conference start date"}>
                                                            <Button
                                                            style={{fontWeight:"500"}}
                                                            className="btn-border"
                                                                onClick={() => setGoToConferenceDate(!goToConferenceStartDate)}
                                                            >
                                                                <Space><UserSwitchOutlined /> Go to Conference start date</Space>
                                                            </Button>
                                                        </Tooltip>
                                                    </div>}
                                                    {isMobile && <div style={{ marginRight:"10px"}}>
                                                        <Tooltip title={"Go to Conference start date"}>
                                                            <Button
                                                                onClick={() => {setGoToConferenceDate(!goToConferenceStartDate)}}
                                                                style={{fontWeight:"500"}}
                                                                className='btn-border'
                                                            >
                                                                    <Space style={{columnGap:'4px'}}><UserSwitchOutlined /> {!isMobile&&'Go to Conference start date'}</Space>
                                                            </Button>
                                                        </Tooltip> 
                                                    </div>}
                                                    {userDetails?.role_access?.home?.conferences?.edit && <div style={{ marginRight:"10px"}}>
                                                        <Tooltip title={"Click to add a new meeting"}>
                                                            <Button
                                                                style={{
                                                                    background: "#000000",
                                                                fontWeight:"500"}}
                                                                type="primary"
                                                                onClick={() => addMeeting()}
                                                            >
                                                                <Space><PlusOutlined />{!isMobile&&'Add New Meeting'}</Space>
                                                            </Button>
                                                        </Tooltip>
                                                    </div>}
                                                   
                                            </div>
                                            {/* <Row>
                                                <Col
                                                    xs={{ flex: "30%" }}
                                                    sm={{ flex: "33%" }}
                                                    md={{ flex: "33%" }}
                                                    lg={{ flex: "33%" }}
                                                    xl={{ flex: "33%" }}
                                                >
                                                    <div style={{ float: "right", position:"absolute"}} >
                                                    <Upload {...props} accept=".xls, .xlsx" maxCount={1}>
                                                    <Button style={{ background: "#000000" }}  type="primary" icon={<UploadOutlined />}></Button>
                                                    </Upload>
                                                    </div>
                                                </Col>
                                                <Col
                                                    xs={{ flex: "30%" }}
                                                    sm={{ flex: "33%" }}
                                                    md={{ flex: "30%"}}
                                                    lg={{ flex: "33%" }}
                                                    xl={{ flex: "33%" }}
                                                >
                                                    <div style={{ float: "right" }}>
                                                        <Button
                                                            style={{ background: "#000000" }}
                                                            type="primary"
                                                            onClick={handleFileDownload}
                                                        >
                                                            <DownloadOutlined />
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col
                                                    xs={{ flex: "30%" }}
                                                    sm={{ flex: "33%" }}
                                                    md={{ flex: "33%" }}
                                                    lg={{ flex: "33%" }}
                                                    xl={{ flex: "33%" }}
                                                >
                                                    <div style={{ float: "right", marginRight: "6px" }}>
                                                        {/* {userDetails.admin_access && windowSize$ === "xs" && ( */}
                                                            {/* <Button
                                                                style={{
                                                                    background: "#E0B41C"}}
                                                                type="primary"
                                                                onClick={() => addMeeting()}
                                                            >
                                                                Add
                                                            </Button>
                                                        {/* )} */}
                                                    {/* </div>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                </Row>
                                <Divider style={{margin: '16px 0 0 0'}}/>

                                <div className="detailsList">
                                    {/* <Row gutter={[16, 16]}>
                                        <Col
                                            className={classNames("", {
                                                "class-33":
                                                    windowSize$ === "lg" ||
                                                    windowSize$ === "xl" ||
                                                    windowSize$ === "xxl",
                                                "width-100":
                                                    windowSize$ === "xs" ||
                                                    windowSize$ === "sm" ||
                                                    windowSize$ === "md",
                                            })}
                                        >
                                            <div
                                                className="agenda-header"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                <Title level={5} className="text-center">
                                                    Conference Agenda
                                                </Title>
                                                <div className="text-center">
                                                    <Text className="text-center">Please refer to</Text>
                                                    <a
                                                        href="https://www.aad.org/member/meetings-education/am24"
                                                        target="_blank"
                                                        className="text-center"
                                                        style={{ marginTop: "5%" }}
                                                    >
                                                        {" "}
                                                        AAD{" "}
                                                    </a>
                                                    <Text className="text-center">conference page</Text>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            className={classNames("", {
                                                "class-33":
                                                    windowSize$ === "lg" ||
                                                    windowSize$ === "xl" ||
                                                    windowSize$ === "xxl",
                                                "width-100":
                                                    windowSize$ === "xs" ||
                                                    windowSize$ === "sm" ||
                                                    windowSize$ === "md",
                                            })}
                                        >
                                            {attendeeList && attendeeList.length > 0 && (
                                                <div>
                                                    <Title level={5}>Attendee List</Title>
                                                    <Select
                                                        mode="multiple"
                                                        defaultValue={attendeeFilterType}
                                                        allowClear
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        showSearch
                                                        placeholder="Please select"
                                                        onChange={handleAttendeeChange}
                                                        options={attendeeList}
                                                        filterOption={filter}
                                                        maxTagCount="responsive"
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                        <Col
                                            className={classNames("", {
                                                "class-33":
                                                    windowSize$ === "lg" ||
                                                    windowSize$ === "xl" ||
                                                    windowSize$ === "xxl",
                                                "width-100":
                                                    windowSize$ === "xs" ||
                                                    windowSize$ === "sm" ||
                                                    windowSize$ === "md",
                                            })}
                                        >
                                            {meetingType && meetingType.length > 0 && (
                                                <div>
                                                    <Title level={5}>Meeting Type</Title>
                                                    <Select
                                                        mode="multiple"
                                                        defaultValue={filterType}
                                                        allowClear
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Please select"
                                                        onChange={handleMeetingChange}
                                                        options={meetingType}
                                                        filterOption={filterOption}
                                                        showSearch
                                                        maxTagCount="responsive"
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </Row> */}
                                    <div
                                        style={{ width: "100%" }}
                                        className={classNames("", {
                                            "margin-classs":
                                                windowSize$ === "lg" ||
                                                windowSize$ === "xl" ||
                                                windowSize$ === "xxl",
                                            "margin-class":
                                                windowSize$ === "xs" ||
                                                windowSize$ === "sm" ||
                                                windowSize$ === "md",
                                        })}
                                    >
                                        <div className="schedule-agenda-height calender-big">
                                            {defaultFilter && defaultFilter?.calenderDate && view?.current!=='agenda'&&(
                                                <Calendar
                                                    defaultView={"day"}
                                                    view={defaultView}
                                                    defaultDate={defaultFilter?.calenderDate}
                                                    onSelectSlot={(slot)=>defaultView === 'day' && navigateWithProps(slot)}
                                                    localizer={localizer}
                                                    events={events}
                                                    formats={formats}
                                                    startAccessor="start"
                                                    endAccessor="end"
                                                    eventPropGetter={eventPropGetter}
                                                    // allDayMaxRows={1}
                                                    onView={handleViewChange}
                                                    onSelectEvent={(event) => {
                                                        onCalenderSelect(event);
                                                    }}
                                                    value={currentDate}
                                                    // onNavigate={handleNavigate}
                                                    resizable
                                                    components={components}
                                                    scrollToTime={new Date()}
                                                    selectable
                                                    date={new Date(currentDate)}
                                                    onDrillDown={(date, view) => {
                                                        setCurrentDate(new Date(date))
                                                        handleViewChange('day');
                                                        // Handle the click event here
                                                    }}
                                                    min={new Date(2024, 8, 20, 0, 0)}
                                                    max={new Date(2024, 8, 20, 23, 59)}
                                                    step={30}
                                                    timeslots={1}
                                                />
                                            )}
                                            {view?.current ==='agenda' &&
                                            <Agenda handleNavigate={handleNavigate} handleAgendaMonthChange={handleAgendaMonthChange} onCalenderSelect={onCalenderSelect} userDetails={userDetails} events={events} view={view} currentDate={currentDateRef} updateView={handleViewChange}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="custom-drawer">
                    { open &&  
                        <Drawer
                        title="Enter Synchronization details"
                        // width={720}
                        size="default"
                        onClose={onClose}
                        open={open}
                        styles={{
                            body: {
                            paddingBottom: 80,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            paddingTop:'0px'
                            },
                        }}
                        // extra={
                        //   <Space>
                        //     <Button onClick={onClose}>Cancel</Button>
                        //     <Button onClick={onClose} type="primary">
                        //       Submit
                        //     </Button>
                        //   </Space>
                        // }
                        footer={
                            <Space>
                            <Button style={{background: '#000000'}} onClick={onSubmit} type="primary">
                                Apply
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                            </Space>
                        }
                        >
                        <Form layout="vertical" hideRequiredMark>
                            
                            <div className="form-item-mail" style={{marginBottom:'15px'}}>
                            <label class="text-only text-ellipsis-one-line-black " style={{marginBottom:'8px'}}>
                                Start Date
                            </label>
                                <DatePicker
                                    defaultValue={syncinputs?.start_date && dayjs(syncinputs?.start_date)}
                                    value={syncinputs?.start_date && dayjs(syncinputs?.start_date)}
                                    // defaultValue={inputs?.start_date}
                                    name="start_date"
                                    style={{ width: '100%' }}
                                    // showTime
                                    // format="YYYY-MM-DD"
                                    placeholder="Select Start Date"
                                    onChange={(date, dateString) => { handleCalender(date, dateString, "start_date") }}
                                    // onOk={onOk}
                                />
                            </div>
                            <div className="form-item-mail" style={{marginBottom:'5px'}}>
                            <label class="text-only text-ellipsis-one-line-black " style={{marginBottom:'8px'}}>
                                End Date
                            </label>
                                <DatePicker
                                    defaultValue={syncinputs?.end_date && dayjs(syncinputs?.end_date)}
                                    value={syncinputs?.end_date && dayjs(syncinputs?.end_date)}
                                    // defaultValue={inputs?.start_date}
                                    name="end_date"
                                    style={{ width: '100%' }}
                                    // showTime
                                    // format="YYYY-MM-DD"
                                    placeholder="Select Start Date"
                                    onChange={(date, dateString) => { handleCalender(date, dateString, "end_date") }}
                                    // onOk={onOk}
                                />
                            </div>
                        </Form>
                        </Drawer>
                    }
                    { filterFlag &&  
                        <Drawer
                        title="Enter filter details"
                        // width={720}
                        size="default"
                        onClose={onCloseFilter}
                        open={filterFlag}
                        styles={{
                            body: {
                            paddingBottom: 80,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            paddingTop:'0px'
                            },
                        }}
                        // extra={
                        //   <Space>
                        //     <Button onClick={onClose}>Cancel</Button>
                        //     <Button onClick={onClose} type="primary">
                        //       Submit
                        //     </Button>
                        //   </Space>
                        // }
                        footer={
                            <Space>
                            <Button style={{background: '#000000'}} onClick={onSubmitFilters} type="primary">
                                Apply
                            </Button>
                            <Button onClick={onCloseFilter}>Cancel</Button>
                            <Button onClick={onResetFilters}>Reset</Button>
                            </Space>
                        }
                        >
                                <Form layout="vertical" hideRequiredMark>

                                    <div className="form-item-mail" style={{ marginBottom: '15px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            Attendee List
                                        </label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            showSearch
                                            placeholder="Please select"
                                            onChange={handleAttendeeChangeFilter}
                                            value={attendeeFilterType}
                                            dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: "8px 0" }} />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      padding: "0 8px 4px",
                                                      gap: "8px",
                                                    }}
                                                  >
                                                      <Button
                                                      type="text"
                                                      icon={<UserOutlined />}
                                                      onClick={handleUserId}
                                                    >
                                                      Just Me
                                                    </Button>
                                                    <Button
                                                      type="text"
                                                      icon={<SelectOutlined />}
                                                      onClick={selectAllAttendees}
                                                    >
                                                      Select All
                                                    </Button>
                                                    <Button
                                                      type="text"
                                                      icon={<ClearOutlined />}
                                                      onClick={() => setAttendeeFilterTypeData([])}
                                                    >
                                                      Clear All
                                                    </Button>
                                                  </div>
                                                </>
                                              )}
                                            options={attendeeList}
                                            defaultValue={attendeeFilterType}
                                            filterOption={filter}
                                            maxTagCount={5}
                                        />
                                    </div>
                                    <div className="form-item-mail" style={{ marginBottom: '5px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            Meeting Type
                                        </label>
                                        <Checkbox.Group onChange={handleMeetingChangeFIlter} defaultValue={filterType}>
                                        {/* direction={} */}
                                            <Space>
                                                {meetingType?.map((option) => (
                                                    <>
                                                        <Checkbox value={option?.value}>
                                                            {option?.label}
                                                        </Checkbox>
                                                    </>
                                                ))}
                                            </Space>

                                        </Checkbox.Group>
                                    </div>
                                    {/* <div className="form-item-mail" style={{ marginBottom: '5px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            Meeting Type
                                        </label>
                                        <Select
                                            mode="multiple"
                                            defaultValue={filterType}
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="Please select"
                                            onChange={handleMeetingChangeFIlter}
                                            value={filterType}
                                            options={meetingType}
                                            optionRender={(option) => (
                                                <>
                                                    {option.data?.value === "all" ?
                                                        <Space>
                                                            {option.data.label}
                                                        </Space>
                                                        :
                                                        <Space>
                                                            <span role="img" aria-label={option.data.label}>
                                                                <CheckCircleTwoTone twoToneColor={option.data.color} />
                                                            </span>
                                                            {option.data.label}
                                                        </Space>
                                                    }
                                                </>
                                            )}
                                            filterOption={filterOption}
                                            showSearch
                                            maxTagCount="responsive"
                                        />
                                    </div> */}
                                    <div className="form-item-mail" style={{ marginBottom: '15px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            Objective  List
                                        </label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            showSearch
                                            placeholder="Please select"
                                            onChange={handleObjectives}
                                            dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: "8px 0" }} />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      padding: "0 8px 4px",
                                                      gap: "8px",
                                                    }}
                                                  >
                                                    <Button
                                                      type="text"
                                                      icon={<SelectOutlined />}
                                                      onClick={selectAllObjectives}
                                                    >
                                                      Select All
                                                    </Button>
                                                    <Button
                                                      type="text"
                                                      icon={<ClearOutlined />}
                                                      onClick={() => setObjectiveValue([])}
                                                    >
                                                      Clear All
                                                    </Button>
                                                  </div>
                                                </>
                                              )}
                                            value={objectiveValue}
                                            options={objectiveList}
                                            defaultValue={objectiveValue}
                                            filterOption={filter}
                                            maxTagCount={5}
                                        />
                                    </div>
                                    <div className="form-item-mail" style={{ marginBottom: '15px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            FollowUp List
                                        </label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            showSearch
                                            placeholder="Please select"
                                            onChange={handleFollowUps}
                                            dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: "8px 0" }} />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      padding: "0 8px 4px",
                                                      gap: "8px",
                                                    }}
                                                  >
                                                    <Button
                                                      type="text"
                                                      icon={<SelectOutlined />}
                                                      onClick={selectAllFollowUps}
                                                    >
                                                      Select All
                                                    </Button>
                                                    <Button
                                                      type="text"
                                                      icon={<ClearOutlined />}
                                                      onClick={() => setFollowUpValue([])}
                                                    >
                                                      Clear All
                                                    </Button>
                                                  </div>
                                                </>
                                              )}
                                              defaultValue={followUpValue}
                                            value={followUpValue}
                                            options={followUpList}
                                            filterOption={filter}
                                            maxTagCount={5}
                                        />
                                    </div>
                                    <div className="form-item-mail" style={{ marginBottom: '15px' }}>
                                        <label class="text-only text-ellipsis-one-line-black " style={{ marginBottom: '8px' }}>
                                            HCP  List
                                        </label>
                                        <Select
                                            mode="multiple"
                                            
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            showSearch
                                            placeholder="Please select"
                                            onChange={handleKols}
                                            dropdownRender={(menu) => (
                                                <>
                                                  {menu}
                                                  <Divider style={{ margin: "8px 0" }} />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      padding: "0 8px 4px",
                                                      gap: "8px",
                                                    }}
                                                  >
                                                    <Button
                                                      type="text"
                                                      icon={<SelectOutlined />}
                                                      onClick={selectAllKols}
                                                    >
                                                      Select All
                                                    </Button>
                                                    <Button
                                                      type="text"
                                                      icon={<ClearOutlined />}
                                                      onClick={() => setKolValue([])}
                                                    >
                                                      Clear All
                                                    </Button>
                                                  </div>
                                                </>
                                              )}
                                              value={kolValue}
                                              options={kolList}
                                              defaultValue={kolValue}
                                            filterOption={filter}
                                            maxTagCount={5}
                                        />
                                    </div>
                                </Form>
                        </Drawer>
                    }
                    </div>
                </>
                :
                <>
                    <SyncMeeting id={id} syncMeetingData={syncMeetingData} syncPayload={syncinputs && syncinputs}  setCloseSyncFlag={(e)=>{onCloseSync(e)}}/>
                </>
            }
        </div>
    );
}

const CustomToolBar=(props,setDefaultViewData)=>{
    const navigate = useNavigate();
    const { Title } = Typography;
    const { width } = useWindowSize();
    const isMobile = width <= 768;

    const goToBack = () => {
        props.onNavigate('PREV');
        props.handleNavigate('PREV',props?.date);
      };
    const goToNext = () => {
        props.onNavigate('NEXT');
        props.handleNavigate('NEXT',props?.date);
      };
    
      const goToToday = () => {
        props.handleNavigate('TODAY',props?.date);
        props.onView('day');
        props.updateView('day')
      };
    
      const handleView = (event) => {
        props.onView(event);
        props.updateView(event,props?.date)
      };
      const handleMonthChange = (month) => {
        const newDate = new Date(props.date.getFullYear(), month, 1);
        props.handleNavigate('DATE', newDate);
        props.onView('month');
        props.updateView('month')
      };
      const handleYearChange = (year) => {
        const newDate = new Date(year, new Date(props.date).getMonth(), 1);
        props.handleNavigate('DATE', newDate);
        props.onView('month');
        props.updateView('month');
      };

      const handleTimeZone = (e) => {
        props.handleTimeZoneChange(e);
      }


    
      const currentYear = new Date(props?.currentDate?.current).getFullYear() || new Date().getFullYear();
      const startYear = currentYear - 10;
      const endYear = 2100;

   return(
       <>
           {
               isMobile ?  (
                   <>
                       <div style={{ display: "flex", alignItems: "center" }}>
                           <Title level={5} style={{ paddingRight: '5px', margin: "0 10px 0 0" }} onClick={goToToday}>
                               <CalendarOutlined style={{ marginRight: "10px" }} />Today
                           </Title>
                           <Space>
                               <LeftOutlined style={{ fontSize: "16px" }} onClick={goToBack} />
                               <RightOutlined style={{ fontSize: "16px" }} onClick={goToNext} />
                           </Space>
                           <span className="rbc-toolbar-label">
                               <Select suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />} onChange={handleMonthChange} className="no-border" value={new Date(props?.currentDate?.current).getMonth()}>
                                   {Array.from({ length: 12 }).map((_, i) => (
                                       <MenuItem key={i} value={i}>
                                           {new Date(2024, i, 1).toLocaleString('default', { month: 'long' })}  {/* Replace with dynamic year if needed */}
                                       </MenuItem>
                                   ))}
                               </Select>
                               {/* {new Date(props?.currentDate?.current).getFullYear() || new Date().getFullYear()} */}
                               <Select
                                   suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                   onChange={handleYearChange}
                                   className="no-border"
                                   value={currentYear}
                               >
                                   {Array.from({ length: endYear - startYear + 1 }).map((_, i) => (
                                       <MenuItem key={i} value={startYear + i}>
                                           {startYear + i}
                                       </MenuItem>
                                   ))}
                               </Select>
                           </span>
                       </div>
                       <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: "5px" }}>
                           <Select
                               defaultValue={props?.defaultValue}
                               style={{ width: 165 }}
                               onChange={handleTimeZone}
                               options={props?.options}
                           />
                           <Select value={props.view} onChange={handleView} style={{ width:120,marginLeft:'15px' }}>
                               <MenuItem value="month">Month</MenuItem >
                               <MenuItem value="week">Week</MenuItem >
                               <MenuItem value="day">Day</MenuItem >
                               <MenuItem value="agenda">Agenda</MenuItem >
                           </Select>
                       </div>
                   </>
               ) : (
           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
               <div style={{ display: "flex", alignItems: "center" }}>
                   <Title level={5} style={{ paddingRight: '5px', margin: "0 10px 0 0" }} onClick={goToToday}>
                       <CalendarOutlined style={{ marginRight: "10px" }} />Today
                   </Title>
                   <Space>
                       <LeftOutlined style={{ fontSize: "16px" }} onClick={goToBack} />
                       <RightOutlined style={{ fontSize: "16px" }} onClick={goToNext} />
                   </Space>
                   <span className="rbc-toolbar-label">
                       <Select suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />} onChange={handleMonthChange} className="no-border" value={new Date(props?.currentDate?.current).getMonth()}>
                           {Array.from({ length: 12 }).map((_, i) => (
                               <MenuItem key={i} value={i}>
                                   {new Date(2024, i, 1).toLocaleString('default', { month: 'long' })}  {/* Replace with dynamic year if needed */}
                               </MenuItem>
                           ))}
                       </Select>
                       {/* {new Date(props?.currentDate?.current).getFullYear() || new Date().getFullYear()} */}
                       <Select
                           suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                           onChange={handleYearChange}
                           className="no-border"
                           value={currentYear}
                       >
                           {Array.from({ length: endYear - startYear + 1 }).map((_, i) => (
                               <MenuItem key={i} value={startYear + i}>
                                   {startYear + i}
                               </MenuItem>
                           ))}
                       </Select>
                       <Select
                           defaultValue={props?.defaultValue}
                           style={{ width: 165 }}
                           onChange={handleTimeZone}
                           options={props?.options}
                       />
                   </span>
               </div>
               <div>
                   <Select value={props.view} onChange={handleView} style={{ width: isMobile ? 60 : 120 }}>
                       <MenuItem value="month">Month</MenuItem >
                       <MenuItem value="week">Week</MenuItem >
                       <MenuItem value="day">Day</MenuItem >
                       <MenuItem value="agenda">Agenda</MenuItem >
                   </Select>
               </div>
           </div>
           )
      }
       </>
  )
}
const CustomHeader = ({ date,currentDate,defaultView}) => {
    const formattedDate = moment(date).format('DD');
    const dayOfWeek = moment(date).format('dddd');
    return (
      <div style={{ display: 'block', paddingRight: '10px' }}>
        {defaultView !=='month'&&<p style={{fontSize:"21px",textAlign:"left",fontWeight:500,margin:0}}>{formattedDate}</p>}
        <span style={{fontWeight:defaultView === 'month' ? '700':"500",padding:defaultView === 'month' && '12px 15px',fontSize:defaultView === 'month' ? '14px' :'13px',display:'flex',width:"100%"}}>{dayOfWeek}</span>
      </div>
    );
  };
const CustomTimeHeader = (props) => {
    const formattedDate = moment(props.currentDate?.current).format('DD');
    const dayOfWeek = moment(props.currentDate?.current).format('dddd');
    return (
        <div style={{ display: 'block', paddingRight: '10px' }}>
        <p style={{fontSize:"21px",margin:0}}>{formattedDate}</p>
        <span style={{fontSize:'13px'}}>{dayOfWeek}</span>
      </div>
    );
  };
