import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import AntBuilder from "../../../Shared/Components/Ant-DynamicFormBuilder/Ant-DynamicFormBuilder";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Row,
    Col,
    Layout,
    Card,
    Modal
  } from "antd";
  import { useSharedState } from "../../../Shared/Services/WindowSizeService";
  import classNames from "classnames";
  import AppIcon from "../../../Assets/icons/app-icon.svg"
  import { ExclamationCircleFilled } from "@ant-design/icons";
  import dayjs from "dayjs";
  import "./SurveyDetails.scss";
  const SurveyDetails = ({...props}) => {
    const [submittedBy, setSubmittedBy] = useState();
    const [submittedOn, setSubmittedOn] = useState();
    const [surveyName,setSurveyName] = useState('');
    const [data, setData] = useState();
    const [defaultFormData, setDefaultFormData] = useState();
    const [formConfig, setFormConfig] = useState();
    const [surveyData, setSurveyData] = useState({});
    const [currentSurvey, setCurrentSurvey] = useState();
    const [currentSurveyResponseId, setCurrentSurveyResponseId] = useState();
    const [surveyFlag, setSurveyFlag] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState();
    const userDetails = JSON.parse(localStorage.getItem("surveysUserDetails"));
    const navigate = useNavigate();
    const hierarchy=[{ label: 'Survey List', link: '/home/surveyList' }, { label: ' ', link: '' }, { label: 'Survey Details', link: '' }];
    const windowSize$ = useSharedState();
    const { confirm } = Modal;

    useEffect(() => {
        const payload = JSON.parse(localStorage.getItem('surveyKeys'));
        callForm(payload?.survey, payload?.surveyID,payload?.surveyTemplateId,payload?.surveyResponseId);
    },[]);

    const redirectToUrl = (url) => {
      window.open(url);
    }


    const callForm = (survey, surveyID,surveyTemplateId,surveyResponseId) => {

        console.log(survey,"clic")
        // if(survey==="medical"){}
        let payload= { 
          "surveyID":surveyID,
          "surveyTemplateId" : surveyTemplateId,
          "surveyResponseId": surveyResponseId ? surveyResponseId : null,
        }
        ServiceUtils.postRequest("surveyTemplate", payload).then(
          (response) => {
            if (response.status === 200 && response?.data.status) {
              if(response?.data?.url) {
                redirectToUrl(response?.data?.url)
              }
              console.log(response,"response")
              if(response?.data?.defaultData){
                let Data = JSON.parse(JSON.stringify(response?.data?.defaultData))
                setDefaultFormData(Data)
                setData(Data);
              } 
              if(response?.data?.submittedBy){
                setSubmittedBy(response?.data?.submittedBy)
              }
              else{
                setSubmittedBy()
              }
              if(response?.data?.submittedOn){
                setSubmittedOn(response?.data?.submittedOn)
              }
              if(response?.data?.surveyName){
                setSurveyName(response?.data?.surveyName)
              }
              else{
                setSubmittedOn()
              }
              let dependantKey;
              let dependantValue;
              let dependantFieldsArray = [];
              let surveyResponse = JSON.parse(JSON.stringify(response?.data?.data));
              surveyResponse?.forEach((submitted_resp,index) => {
                submitted_resp.forEach((resp) => {
                  if(resp.dependantFields) {
                     dependantKey = resp?.key;
                     dependantValue = response?.data?.defaultData[resp?.key];
                     dependantFieldsArray = resp.dependantFields;
                  }
                  if(dependantFieldsArray.length > 0 && dependantValue) {
                      if(dependantFieldsArray.includes(resp?.key)) {
                        if(dependantKey === resp?.fieldDependencyKeys?.dependenceKey) {
                          if(resp?.fieldDependencyKeys?.dependencyValue === dependantValue) {
                            resp.disabled = false;
                            dependantKey = null;
                            dependantValue = null;
                            dependantFieldsArray = [];
                          } 
                        }
                      }
                  }
                  //logic for enabling/disabling mandatory section
                  if (resp?.mandatoryFieldDependencyKeys) {
                    if (resp?.mandatoryFieldDependencyKeys?.dependencyValue === response?.data?.defaultData[resp?.mandatoryFieldDependencyKeys?.dependenceKey]) {
                      resp.rules[0]['required'] = true;
                    } else {
                      resp.rules[0]['required'] = false;
                    }
                  }
                })
              })
              setFormConfig(surveyResponse);
              setSurveyData(JSON.parse(JSON.stringify(response?.data)));
              setCurrentSurvey(surveyID);
              if(surveyResponseId){
                setCurrentSurveyResponseId(surveyResponseId)
              }
              setSurveyFlag(true);
              setSelectedSurvey(survey);
            } else if (response.status === 401) {
              navigate('/logout');
            }
          },
          (error) => {
            alertService.error("Error while fetching data.");
          }
        );
        
      };

      const checkDisable = () => {
        let flag = false;
        let activeTab = JSON.parse(localStorage.getItem('activeSurveyTab'));
        if(activeTab==="submittedResponses"){
          if(data && defaultFormData){
            for (const [key, value] of Object.entries(data)) {
              if(key in defaultFormData ){
                if(defaultFormData[key]!==data[key]){                  
                  if(key==="quarter"){
                    if(typeof(data[key])==="object"){
                      let beforeQuarter = Math.floor((dayjs(new Date(defaultFormData[key]))?.month() + 3) / 3);
                      let quarter = Math.floor((data[key]?.month() + 3) / 3);
                      if(`Q${beforeQuarter}-${dayjs(new Date(defaultFormData[key]))?.year()}`===`Q${quarter}-${data[key]?.year()}`){
                      }
                      else{
                        flag=true;
                      }
                    }  
                  }
                  else{
                    if(Array.isArray(defaultFormData[key]) && Array.isArray(data[key])){
                      if(arraysEqual(defaultFormData[key], data[key])){
                      }
                      else{
                        flag=true;
                      }
                    }
                    else{
                      flag=true;
                    }
                  }
                  
                }
              }
              else{
                if(data[key]){
                  flag=true;
                }
              }
            }
          }
        }
        else{
          flag=true;
        }
        return flag;   
      }

      const arraysEqual = (arr1, arr2) => {
        // First, check if they have the same length
        console.log(arr1,arr2);
        if (arr1.length !== arr2.length) {
            return false;
        }
        
        // Then, compare each element
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        
        // If we pass both checks, the arrays are equal
        return true;
    }
    


      const handle = (action) => {
        if(action?.payload?.allData){
          setData(action?.payload?.allData);
        }
        let foundDependency = false;
        let foundDependencyKey = "";
        let foundDependencyService="";
        let foundDependencyPayloadID="";
        let foundDisableDependency = false;
        const modifiedEmployees = formConfig[0]?.map(obj => {
          if(action?.type === "dragAndDrop"){
            let tempData = {...data};
            let options = insertAt(action?.payload?.draggedItemData?.options,action?.payload?.draggedItemData?.element,action?.payload?.index)
            tempData[action?.payload?.draggedItemData?.key] = options;
            let tempConfig = JSON.parse(JSON.stringify(formConfig))
            let updatedConfig = updateFormConfig(action?.payload?.draggedItemData?.key,'options',options,tempConfig)
            setData({...tempData});
            setFormConfig(JSON.parse(JSON.stringify(updatedConfig)))
          }
          else if(action?.type === 'addNewOption'){
            let tempData = {...data};
            let tempConfig = JSON.parse(JSON.stringify(formConfig))
            let options=[...action?.field?.options]
            options.push({label:action?.value,value:action?.value})
            let updatedConfig = updateFormConfig(action?.field?.key,'options',options,tempConfig)
            tempData[action.field.key] = action?.value
            setData({...tempData});
            setFormConfig(JSON.parse(JSON.stringify(updatedConfig)))
          } else if(action?.type === 'onChange') {
            const currentFieldData = action.payload.currentFieldData[0];
            if(currentFieldData.key === obj?.fieldDependencyKeys?.dependenceKey) {
              if(currentFieldData?.dependantFields && currentFieldData?.dependantFields?.length) {
                currentFieldData.dependantFields.forEach(dependant => {
                  action.payload.allData[dependant] = [];
                })
              }
                 if(action.payload.allData[currentFieldData.key] === obj.fieldDependencyKeys.dependencyValue) {
                  obj.disabled = false;
                 } else {
                  obj.disabled = true;
                 }
            }
            //logic for enabling mandatory section
            if(currentFieldData.key === obj?.mandatoryFieldDependencyKeys?.dependenceKey) {
              if(action.payload.allData[currentFieldData.key] === obj.mandatoryFieldDependencyKeys.dependencyValue) {
                obj.rules[0]['required'] = true;
               } else {
                obj.rules[0]['required'] = false;
               }
            }
            foundDependency = true;
          }
           if(action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey && action?.payload?.currentFieldData?.[0]?.key){
            if (action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey === obj?.dependencyData?.dependenceKey) {
                foundDependency= true;
                foundDependencyKey=action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey;
                foundDependencyService=action?.payload?.currentFieldData?.[0]?.dependencyData?.ondependenceService;
                let arr=action?.payload?.currentFieldData?.[0]?.dependencyData?.multipleDependencyKeys;
                const obj = {};
                arr.forEach((value, index) => obj[value] = action?.payload?.data[value]);
                foundDependencyPayloadID=JSON.parse(JSON.stringify(obj));
            }
          }
        }
        
        );
    
        if(foundDependency) {
          setFormConfig(formConfig)
        }
        
        if(foundDependency && foundDependencyKey && foundDependencyService && foundDependencyPayloadID){
          dynamicService(foundDependencyKey,action?.payload?.currentFieldData,foundDependencyService,foundDependencyPayloadID)
        }
      };

      function insertAt(array, element, index) {
        // Ensure the index is within the bounds or at the end of the array
        let tempArray = array?.filter(el=>el?.value !== element?.value)
        // Insert the element at the specified index
        tempArray.splice(index, 0, element);
      
        return tempArray;
      }

      const updateFormConfig = (key, updateKey, options, config) => {
        let temp = JSON.parse(JSON.stringify(config));
        temp.forEach((formElRow) => {
          formElRow?.forEach((formEl) => {
            if (formEl?.key === key) {
              formEl[updateKey] = options;
            }
            return formEl;
          });
          return formElRow;
        });
        return temp;
      };
      

      const onFocusField = (focus) => {
        if(focus[0]?.onFocusGetData && focus[0]?.key && focus[0]?.onFocusService){
          dynamicService(focus[0]?.key,focus,focus[0]?.onFocusService)
        }
      }

      const showConfirm = () => {
        confirm({
          title: 'Do you want to continue without saving the survey',
          icon: <ExclamationCircleFilled />,
          content: 'If yes, please proceed by clicking OK',
          onOk() {
            cancelSurvey();
          },
          onCancel() {
          },
        });
      }

      const onCancel = () => {};


      const cancelSurvey = () => {
        navigate('/home/surveyList');
      };

      const dynamicService = (key,fieldData,service,dependenceID) => {
        let payload={
          surveyId: currentSurvey,
          key:key,
          id:Number(dependenceID?.kolId) ? dependenceID : null
        }
        ServiceUtils.postRequest(service,payload).then(
          (response) => {
            if (response.status === 200 && response?.data.status) {
              let foundDependency = false;
              let foundDependencyKey = "";
              if(fieldData[0]?.dependencyData?.dependenceKey===key){
                const modifiedEmployees = formConfig[0]?.map(obj => {
                  let options = JSON.parse(JSON.stringify(response?.data?.data));
                  if(fieldData?.length && fieldData[0]?.dependencyData?.dependenceKey && fieldData[0]?.dependencyData?.dependenceKey===obj?.key){
                    if (fieldData[0]?.dependencyData?.dependenceKey===obj?.key) {
                        foundDependency= true;
                        foundDependencyKey=fieldData[0].dependencyData?.dependenceKey;                       
                        return { ...obj, "options": options };
                    }
                    else{
                      return { ...obj };
                    }
                  }
                  else{
                    return { ...obj };
                  }
                }
                );
                if(foundDependency && foundDependencyKey){
                  setFormConfig([modifiedEmployees])
                }
              }
              else if( fieldData[0]?.onFocusGetData && fieldData[0]?.key===key){
                const modifiedEmployees = formConfig[0]?.map(obj => {
                  let options1=JSON.parse(JSON.stringify(response?.data?.data))
                  if(fieldData[0]?.onFocusGetData && key && fieldData[0]?.key===obj?.key){
                    if (fieldData[0]?.key===key) {
                      foundDependency= true;
                      foundDependencyKey=fieldData[0].onFocusGetData;                     
                      return { ...obj, "options": options1 };
                    }
                    else{
                      return { ...obj };
                    }
                  }
                  else{
                    return { ...obj };
                  }
                }
                );
                if(foundDependency && foundDependencyKey){
                  setFormConfig([modifiedEmployees])
                }
              }             
            } else if (response.status === 401) {
              navigate('/logout');
            }
          },
          (error) => {
            alertService.error("Error while fetching data.");
          }
        );
      }

      const SaveSurvey = () => {
        console.log(data, "payload on submit")
        let payload ={...data};
        let quarter;
        let tempQuarterData;
        if(payload['quarter']){
          tempQuarterData=payload['quarter'] ? payload['quarter'] : dayjs(new Date())
        }
        else{
          tempQuarterData=payload['quarter'] ? payload['quarter'] : dayjs(new Date())
        }
        payload['quarter'] = tempQuarterData
        if(validateSubmit(payload)){
          saveServivce(payload)
        }
        else{
          alertService.error("Please fill all mandatory fields");
        }        
      };

      const validateSubmit = (payData) => {
        let flag = true;
        
        for (let i = 0; i < formConfig[0]?.length; i++) {
          if(formConfig[0][i]?.rules[0]?.required){
            if(payData[formConfig[0][i]?.key]){
              
            }
            else{
              flag= false;
              return false;
            }
          }
        }
        return flag;
      }
    
      const saveServivce = (payloadD) => {
        let payload={
          response: payloadD,
          surveyId:currentSurvey,
          surveyResponseId:currentSurveyResponseId,
          created_time:new Date(),
          created_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        ServiceUtils.postRequest("submitSurveyResponse",payload).then(
          (response) => {
            if (response.status === 200 && response?.data.status) {
              navigate('/home/surveyList')        
            } else if (response.status === 401) {
              navigate('/logout');
            }
          },
          (error) => {
            alertService.error("Error while fetching data.");
          }
        );
      }


    return(
        <>
            <Card
                style={{ width: "100%", height: "fit-content" }}
                bodyStyle={{ padding: "10px" }}
            >
                <BreadcrumbList active={hierarchy} />
            </Card>
            <Layout>
                <Content>
                    <div className="Survey-Details">
                        {surveyFlag && selectedSurvey && (
                            <div style={{ padding: "15px 30px" }}>
                                <div className="kol-search" style={{ paddingBottom: "5px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col
                                            xs={{ flex: "100%" }}
                                            sm={{ flex: "100%" }}
                                            md={{ flex: "100%" }}
                                            lg={{ flex: "50%" }}
                                            xl={{ flex: "50%" }}
                                        >
                                            <div
                                                className={classNames("", {
                                                    "search-kol-mobile":
                                                        windowSize$ === "lg" ||
                                                        windowSize$ === "xl" ||
                                                        windowSize$ === "xxl",
                                                    "search-kol-mobile":
                                                        windowSize$ === "xs" ||
                                                        windowSize$ === "sm" ||
                                                        windowSize$ === "md",
                                                })}
                                            >
                                                <div className="" title="Go to Home" >
                                                    <img className="pointer" style={{ width: '30%' }} src={AppIcon} />
                                                </div>
                                                <h1 style={{ margin: '10px 0' }}>{surveyName}</h1>
                                            </div>
                                        </Col>
                                        <Col
                                            xs={{ flex: "100%" }}
                                            sm={{ flex: "100%" }}
                                            md={{ flex: "100%" }}
                                            lg={{ flex: "50%" }}
                                            xl={{ flex: "50%" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <Row gutter={[16, 16]}>
                                                    <Col>
                                                        {submittedBy && <p style={{ opacity: "0.5" }}>Submitted by <b>{submittedBy}</b></p>}
                                                    </Col>
                                                    <Col>
                                                        {submittedOn && <p style={{ opacity: "0.5" }}>Submitted on <b>{submittedOn}</b></p>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        padding: "15px 30px",
                                        backgroundColor: "white",
                                    }}
                                    className="builder"
                                >
                                    <AntBuilder disable={!userDetails?.surveysUserDetails?.role_access?.home?.surveys?.edit} config={formConfig} onAction={handle} onFocus={onFocusField} data={data} defaultData={defaultFormData} formData={surveyData} />
                                    {formConfig?.length &&
                                        <div style={{ marginTop: 23 }}>
                                            <div className="submit-block">
                                                <div style={{ paddingRight: "10px" }}>
                                                    <Button onClick={showConfirm}> {formConfig[0]?.length ? "Cancel" : "Back"}</Button>
                                                </div>
                                                {formConfig[0]?.length ?
                                                    <Button
                                                        className="edit-meet-button"
                                                        type="primary"
                                                        disabled={checkDisable() === true ? false : true}
                                                        onClick={() => SaveSurvey()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </Content>
            </Layout>
        </>
      
    )
}

export default SurveyDetails;